import useAPICall from "../../../hooks/useAPICall";
import en from "../../../Lang/en.json";
import axios from "axios";

const useGetBrandOrCategories = () => {
  const [data, error, isLoading, callGetData, setSuccessData, setError] =
    useAPICall(undefined, "");

  const defaultFallback = () => {
    setError(en.something_went_wrong);
    setSuccessData(undefined);
  };

  const sortList = (list) => {
    list.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    return list;
  };

  const statusObj = [
    {
      status_code: "200",
      status_txt: "OK",
      callBack: (res) => {
        const data = res.data;
        let response = data.map((r) => ({
          ...r,
          checked: false,
        }));
        response = sortList(response);
        setSuccessData(response);
      },
    },
    {
      status_txt: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_txt: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const getBrandOrCategories = (what) => {
    const url = `${process.env.REACT_APP_API_URL}/viralad/get/brand_or_category/${what}/`;
    axios.defaults.headers.common[
      "Authorization"
    ] = `token ${sessionStorage.getItem("authToken")}`;
    callGetData({
      url,
      method: "GET",
      statusObj,
      defaultFallback,
    });
  };
  return [
    data,
    error,
    isLoading,
    getBrandOrCategories,
    setSuccessData,
    setError,
  ];
};

export default useGetBrandOrCategories;
