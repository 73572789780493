import React, { useState, useEffect } from "react";
import PostAddIcon from '@mui/icons-material/PostAdd';
import CloseIcon from "@mui/icons-material/Close";
import CarouselModal from "../../CarouselModal";
import useUpdateCarousel from "../data/useUpdateCarousel";

const CarouselField = (props) => {
  const { carouselData, setPostResponseMessage } = props;
  const [showCarousel, setShowCarousel] = useState(false);
  const [carouselElements, setCarouselElements] = useState([]);
  const [data, error, isLoading, updateCarousel] = useUpdateCarousel();

  useEffect(() => {
    setCarouselElements([
      {
        name: "Upload",
        img: "",
      },
      {
        name: "Upload",
        img: "",
      },
    ]);
  }, [carouselData]);

  const handleDone = (e, carousel_id) => {
    e.preventDefault();
    setShowCarousel(false);
    let classvalue = document.getElementsByClassName("uploadedImg");
    let holdPathFileName = [];
    let formData = new FormData();
    for (let i = 0; i < classvalue.length; i++) {
      holdPathFileName.push(classvalue[i].files[0]);
    }
    let stringvalue = holdPathFileName.filter((value) => {
      return value !== undefined;
    });

    stringvalue.forEach((item) => formData.append("images", item));
    updateCarousel(formData, carousel_id)
  };

  useEffect(() => {
    if(data) {
      if (data?.status === true) {
        setPostResponseMessage({
          message: "File Uploaded successfully",
          status: true,
          resStatus: data.status,
        });
      } else {
        setPostResponseMessage({
          message: "Upload unsuccessful",
          status: true,
          resStatus: data.status,
        });
      }
    }
  }, [data])

  const openCarousel = () => {
    setShowCarousel(true);
  };

  const closeCarousel = () => {
    setShowCarousel(false);
  };

  const addImageHandler = () => {
    if (carouselElements.length < 5) {
      let temp = [...carouselElements];
      temp.push({
        name: "Upload",
        img: "",
      });
      setCarouselElements(temp);
    } else {
      alert("Carousel cannot containe more than 5 images");
    }
  };

  const removeImageHandler = (index) => {
    if (carouselElements.length > 2) {
      let temp = carouselElements.filter((ele, i) => i !== index);
      setCarouselElements(temp);
    } else {
      alert("Carousel should contain atleast 2 images");
    }
  };

  const setimage = (e, index) => {
    let objectURL = URL.createObjectURL(e.target.files[0]);
    const img = new Image();
    img.src = objectURL;

    let temp = carouselElements.map((ele, i) => {
      if (i === index) {
        return {
          name: e.target.value.split("\\").at(-1),
          img: img.src,
        };
      } else {
        return ele;
      }
    });
    setCarouselElements(temp);
  };

  return (
    <>
      <div className="mb-5">
        <h5 className="nameTitle">{carouselData.name}</h5>
        <div style={{ display: "flex" }}>
          <div className="holder-img">
            <img
              src={`${carouselData.address_url}/${
                carouselData.image_src.split(",")[0]
              }`}
              width={"100%"}
              height={"100%"}
              alt="media-img"
            />
          </div>

          <div className="all-holder">
            <p className="sizeP">SIZE</p>
            <span className=" sp"> test </span> X
            <span className=" sp"> test </span>
            <div className="upload-btn" onClick={openCarousel}>
              edit
            </div>
          </div>
        </div>
        {showCarousel && (
          <div className="CarouselModal" data-aos="fade-down">
            <form
              onSubmit={(e) => handleDone(e, carouselData.id)}
              style={{ height: "100%" }}
            >
              <div className="CarouselModal-main" data-aos="zoom-in">
                <CloseIcon
                  sx={{ cursor: "pointer" }}
                  className="crossCarousel"
                  onClick={closeCarousel}
                />
                <div className="CarouselModal-top">
                  <h1>IMAGES</h1>
                  <div style={{ display: "flex", justifyContent: 'space-between'}}>
                    {carouselElements.map((file, i) => {
                      return (
                        <CarouselModal
                          key={i}
                          index={i}
                          setimage={setimage}
                          file={file}
                          removeImageHandler={removeImageHandler}
                        />
                      );
                    })}
                    <div className="add-image-icon" onClick={addImageHandler}>
                      <PostAddIcon color="#ffffff" />
                    </div>
                  </div>
                </div>

                <div className="CarouselModal-bottom ">
                  <button type="submit" className="">
                    Done
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default CarouselField;
