import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RadioButton from "../../../components/common/Edit/Elements/RadioButton";
import { TemplateDataContext } from "../../../pages/edit";
import useUpdateTemplateName from "../../../components/common/Edit/Elements/data/useUpdateTemplateName";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import en from '../../../Lang/en.json';
import {
  Alert,
  IconButton,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const EditNav = (props) => {
  const navigate = useNavigate();
  const { projectData } = useContext(TemplateDataContext);
  const { activeFrame, setActiveFrame } = props;
  const [templateName, setTemplateName] = useState("");
  const [data, error, isLoading, updateTemplateName] = useUpdateTemplateName();
  const [editNameEnabled, setEditNameEnabled] = useState();
  const [snackbarDetails, setSnackbarDetails] = useState({
    message: "",
    status: false,
    severity: "",
  });

  useEffect(
    function () {
      setTemplateName(projectData ? projectData.name : "");
    },
    [projectData]
  );

  const handleFrameSelect = (frameid) => {
    let frame = projectData.frames.find((frame) => frame.id === frameid);
    setActiveFrame(frame);
  };

  const editInputValue = (e) => {
    setTemplateName(e.target.value);
  };

  const handleUpdateTemplateName = () => {
    setEditNameEnabled(false);
    let dataArray = [projectData.id, templateName];
    updateTemplateName(dataArray);
  };

  const gotoNextFrame = () => {
    let index = projectData.frames.findIndex(
      (frame) => frame.id === activeFrame.id
    );
    setActiveFrame(projectData.frames[(index + 1) % projectData.frames.length]);
  };

  const gotoPreviousFrame = () => {
    let index = projectData.frames.findIndex(
      (frame) => frame.id === activeFrame.id
    );
    if (index === 0) {
      setActiveFrame(projectData.frames[projectData.frames.length - 1]);
    } else {
      setActiveFrame(
        projectData.frames[(index - 1) % projectData.frames.length]
      );
    }
  };

  useEffect(() => {
    if (data) {
      setSnackbarDetails({
        message: `Name Updated Successfully`,
        status: true,
        severity: "success",
      });
    } else if (error === en.something_went_wrong) {
      setSnackbarDetails({
        message: `Error! Name Update failed`,
        status: true,
        severity: "error",
      });
    }
  }, [data, error]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarDetails({ message: "", status: false, severity: "" });
  };

  return (
    <React.Fragment>
      <div className="frames-nav-header bg-white">
        <div className="nav-input-wrapper">
          <IconButton
            size="large"
            className="back-icon"
            onClick={() => navigate("/user/myproject/")}
          >
            <ArrowBackIcon />
          </IconButton>
          {!editNameEnabled ? (
            <Tooltip title="Edit Name" placement="top" arrow>
              <Typography onClick={() => setEditNameEnabled(true)}>
                {templateName}
              </Typography>
            </Tooltip>
          ) : (
            <TextField
              type="text"
              size="small"
              variant="outlined"
              id="template-name"
              disabled={isLoading}
              label="Project Name"
              value={templateName}
              onChange={editInputValue}
              placeholder="Project name"
              onBlur={handleUpdateTemplateName}
              aria-describedby="Project name"
              inputProps={{ "aria-label": "Project name" }}
            />
          )}
        </div>
        <div className="channel">
          <IconButton onClick={gotoPreviousFrame}>
            <ArrowLeftIcon />
          </IconButton>
          <ul>
            {projectData &&
              projectData.frames.map((element, i) => {
                return (
                  <RadioButton
                    handleFrameSelect={handleFrameSelect}
                    key={element.id}
                    frameid={element.id}
                    index={i}
                    activeFrame={activeFrame}
                  />
                );
              })}
          </ul>
          <IconButton onClick={gotoNextFrame}>
            <ArrowRightIcon />
          </IconButton>
        </div>
      </div>
      <Snackbar
        onClose={handleClose}
        autoHideDuration={6000}
        open={snackbarDetails.status}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <div>
          <Alert
            variant="filled"
            onClose={handleClose}
            sx={{ width: "100%" }}
            severity={snackbarDetails.severity ?? "success"}
            action={
              <IconButton size="small" color="inherit" onClick={handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          >
            {snackbarDetails.message}
          </Alert>
        </div>
      </Snackbar>
    </React.Fragment>
  );
};
export default EditNav;
