// -------------- Import Packages --------------------//
import React, { useState, useContext } from "react";
import {
  Box,
  Menu,
  Avatar,
  Divider,
  MenuItem,
  Container,
  IconButton,
  ListItemIcon,
} from "@mui/material";
import "./styles.scss";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import Logout from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";

// ----------------- Import Components -------------------//
import { logout } from "../../../utilities/functions";
import logo from "../../../assets/images/logotool.png";
import { UserDetailsContext, AuthStateContext } from "../../../App";

const Navigation = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const { userDetails } = useContext(UserDetailsContext);
  const { setIsAuthenticated } = useContext(AuthStateContext);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <header sx={{ flex: "0 1 auto" }}>
      <Container>
        <Box className="headwrap">
          <div className="logotop">
            <img src={logo} alt="" width={"100%"} />
          </div>

          {userDetails ? (
            <React.Fragment>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <Avatar
                      sx={{
                        background: "#00b4d8",
                        width: 32,
                        height: 32,
                        p: 0.5,
                      }}
                    >
                      {userDetails.first_name[0].toUpperCase()}
                      {userDetails.last_name[0].toUpperCase()}
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                {/*<MenuItem
                  className="menu_item"
                  onClick={() => {
                    handleClick();
                    navigate(`/user/home`, {
                      replace: true,
                      state: "",
                    });
                  }}
                >
                  <PersonIcon sx={{ marginRight: 2 }} /> My Profile
                </MenuItem>
              <Divider />*/}
                
                <MenuItem
                  className="menu_item"
                  onClick={() => {
                    handleClose();
                    logout(setIsAuthenticated);
                  }}
                >
                  <ListItemIcon>
                    <Logout sx={{ marginRight: 2 }} fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </React.Fragment>
          ) : null}
        </Box>
      </Container>
    </header>
  );
};

export default Navigation;
