import React, { useState, useRef, useEffect } from "react";
import useUpdateYoutubeLink from "../data/useUpdateYoutubeLink";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { Box, TextField, Typography } from "@mui/material";
import "../textFields.scss";

const Urlfield = (props) => {
  const { youtubevideo, index, setLoader, setPostResponseMessage } = props;
  const urlarea = useRef();
  const [inputText, setInputText] = useState(youtubevideo.video_url);
  const [data, error, isLoading, updateYoutubeLink] = useUpdateYoutubeLink();

  const textareafun = (e) => {
    urlarea.current.disabled = false;
  };

  const url_validation = (e) => {
    setInputText(e.target.value);
  };

  useEffect(() => {
    setLoader(false);
    if (data) {
      if (data.status) {
        setPostResponseMessage({
          message: "Link Uploaded",
          status: true,
          resStatus: data.status,
        });
      } else {
        setPostResponseMessage({
          message: "Link Uploaded Fail",
          status: true,
          resStatus: data.status,
        });
      }
    }
  }, [data]);

  const YoutubeUpdate = () => {
    let res = inputText.match(
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
    );

    if (res !== null) {
      setLoader(true);
      let youtube_array = [youtubevideo && youtubevideo.id, inputText];
      updateYoutubeLink(youtube_array);
    } else {
      alert("Invalid URL");
    }
  };

  return (
    <Box className="text-field">
      <Typography className="text-heading" variant="body1">Url: {index + 1}</Typography>
      <div className="input-elements-wrapper">
        <TextField
          multiline
          type="url"
          value={inputText}
          onChange={url_validation}
          onBlur={YoutubeUpdate}
          ref={urlarea}
          disabled
        ></TextField>
        <Box className="action-btn-wrapper">
          <EditIcon onClick={textareafun} />
          <DeleteOutlineOutlinedIcon />
        </Box>
      </div>
      <div className="saving"></div>
    </Box>
  );
};

export default Urlfield;
