import React from "react";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { Modal, Box, Typography, Button } from "@mui/material";

const DeleteValidation = (props) => {

  const { modalState, handleModalClose, deleteTemplate } = props;
  return (
    <Modal
      open={modalState}
      onClose={handleModalClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      aria-describedby="delete-validation-modal"
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          width: "475px",
          height: "275px",
          padding: "20px",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "8px",

          "&:focus-visible": {
            outline: "none",
          },
        }}
      >
        <WarningRoundedIcon
          sx={{ marginBottom: "15px" }}
          color="error"
          fontSize="large"
        />
        <Typography
          id="modal-modal-title"
          sx={{
            fontFamily: "Montserrat-Bold",
            fontSize: "20px",
            color: "#797777",
            marginBottom: "10px",
          }}
          variant="h6"
          component="h2"
        >
          Delete ViralAd
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontFamily: "Montserrat-Regular",
            fontSize: "16px",
            color: "#747474",
            marginBottom: "10px",
          }}
        >
          This will permanently delete the project from your account
        </Typography>
        <Box>
          <Button
            sx={{ marginRight: 1 }}
            color="secondary"
            variant="outlined"
            onClick={handleModalClose}
          >
            CANCEL
          </Button>
          <Button color={"error"} variant="contained" onClick={deleteTemplate}>
            DELETE
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteValidation;
