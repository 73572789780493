import useAPICall from "../../../../../hooks/useAPICall";
import en from "../../../../../Lang/en.json";
import axios from "axios";

const useDeleteMedia = () => {
  const [data, error, isLoading, callGetData, setSuccessData, setError] =
    useAPICall(undefined, "");

  const defaultFallback = () => {
    setError(en.something_went_wrong);
    setSuccessData(undefined);
  };

  const statusObj = [
    {
      status_code: "200",
      status_txt: "OK",
      callBack: (res) => {
        const data = res.data;
        setSuccessData(data);
      },
    },
    {
      status_txt: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_txt: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const deleteMedia = (data) => {
    const url = `${process.env.REACT_APP_API_URL}/viralad/user/delete/media/`;
    
    axios.defaults.headers.common[
      "Authorization"
    ] = `token ${sessionStorage.getItem("authToken")}`;
    const body = { id: data }
    callGetData({
      url,
      method: "DELETE",
      statusObj,
      defaultFallback,
      body,
    });
  };
  return [data, error, isLoading, deleteMedia, setSuccessData, setError];
};

export default useDeleteMedia;
