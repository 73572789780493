import "./styles.scss";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Grid,
  Modal,
  Button,
  TextField,
  InputLabel,
  Typography,
  IconButton,
  FormControl,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import en from "../../../Lang/en.json";
import useRegisterUser from "../data/useRegisterUser";
import SideFirstScreen from "../../../components/common/SideFirstScreen";
import { validator } from "../../../utilities/functions";
import * as CONSTANT from "../../../utilities/constants";
import ViraladLoader from "../../../components/common/ViraladLoader";

const Signup = () => {
  const navigate = useNavigate();
  let registrationResponseRef = useRef({});
  const [
    registrationSuccessResponse,
    registrationError,
    registrationIsLoading,
    callRegisterUser,
  ] = useRegisterUser();

  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);

  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState(false);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailAPIErrorMsg, setEmailAPIErrorMsg] = useState("");

  const [contact, setContact] = useState("");
  const [contactError, setContactError] = useState(false);

  const [organisation, setOrganisation] = useState("");
  const [orgError, setOrgError] = useState(false);

  const [designation, setDesignation] = useState("");
  const [designationError, setDesignationError] = useState(false);

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (typeof registrationError !== "string") {
      registrationError.forEach((err) => {
        if (err[0] === "email") {
          setEmailAPIErrorMsg(err[1]);
        } else {
          setEmailAPIErrorMsg("");
        }
      });
    }
  }, [registrationError, registrationIsLoading]);

  useEffect(() => {
    if (registrationSuccessResponse) {
      setEmail("");
      setContact("");
      setLastName("");
      setPassword("");
      setFirstName("");
      setDesignation("");
      setOrganisation("");
      setConfirmPassword("");
      registrationResponseRef.current = registrationSuccessResponse.data;
      setModalOpen(true);
    }
  }, [registrationSuccessResponse]);

  const handleChange = (e) => {
    if (e.target.name === "firstName") {
      setFirstName(e.target.value);
      setFirstNameError(
        !validator(e.target.value, e.target.name, CONSTANT.FIRSTNAME_LENGTH)
      );
    } else if (e.target.name === "lastName") {
      setLastName(e.target.value);
      setLastNameError(
        !validator(e.target.value, e.target.name, CONSTANT.LASTNAME_LENGTH)
      );
    } else if (e.target.name === "email") {
      setEmail(e.target.value);
    } else if (e.target.name === "contact") {
      setContact(e.target.value);
      setContactError(
        !validator(e.target.value, e.target.name, CONSTANT.CONTACT_LENGTH)
      );
    } else if (e.target.name === "organisation") {
      setOrganisation(e.target.value);
      setOrgError(
        !validator(e.target.value, e.target.name, CONSTANT.ORGANISATION_LENGTH)
      );
    } else if (e.target.name === "designation") {
      setDesignation(e.target.value);
      setDesignationError(
        !validator(e.target.value, e.target.name, CONSTANT.DESIGNATION_LENGTH)
      );
    } else if (e.target.name === "password") {
      setPassword(e.target.value);
    } else if (e.target.name === "confirmPassword") {
      setConfirmPassword(e.target.value);
    }
    handleIsDisabled();
  };

  const handleInvalidEmail = () => {
    setEmailError(!validator(email, "email"));
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClicksetShowPassword = () =>
    setShowConfirmPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleRegister = () => {
    let bodyStructure = {
      email: email,
      password: password,
      last_name: lastName,
      first_name: firstName,
      phone_number: contact,
      organisation: organisation,
      designation: designation,
      role: 1,
    };
    callRegisterUser(bodyStructure);
  };

  const handleIsDisabled = () => {
    let isDisabled =
      orgError ||
      emailError ||
      contactError ||
      passwordError ||
      lastNameError ||
      firstNameError ||
      designationError ||
      email.length === 0 ||
      contact.length === 0 ||
      password.length === 0 ||
      lastName.length === 0 ||
      firstName.length === 0 ||
      designation.length === 0 ||
      organisation.length === 0 ||
      confirmPassword.length === 0;
    return isDisabled;
  };

  const handlePasswordError = () => {
    if (password === confirmPassword) {
      setPasswordError(false);
    } else {
      setPasswordError(true);
    }
  };

  const handleModalClose = () => {
    navigate("/login", { replace: true, state: "" });
    setModalOpen(false);
  };

  return (
    <Box className="signup">
      <Box className="form" sx={{ padding: 4 }}>
        <Typography className="form_topic" variant="h2">
          Sign Up with you email!
        </Typography>
        <Grid container spacing={2} direction="row" className="input-fields">
          <Grid
            item
            className="input-field"
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={6}>
              <TextField
                name="firstName"
                type="text"
                defaultValue={""}
                variant="outlined"
                label="First Name"
                fullWidth
                error={firstNameError}
                value={firstName}
                sx={{
                  input: { color: firstNameError ? "#e71d36" : "#06d6a0" },
                }}
                inputProps={{ maxLength: CONSTANT.FIRSTNAME_LENGTH }}
                onChange={handleChange}
                helperText={firstNameError ? en.alpha_numeric_only : null}
              ></TextField>
            </Grid>
            <Grid item xs={5}>
              <TextField
                xs={6}
                fullWidth
                defaultValue={""}
                type="text"
                name="lastName"
                label="Last Name"
                value={lastName}
                variant="outlined"
                error={lastNameError}
                onChange={handleChange}
                sx={{ input: { color: lastNameError ? "#e71d36" : "#06d6a0" } }}
                helperText={lastNameError ? en.alpha_numeric_only : null}
                inputProps={{ maxLength: CONSTANT.LASTNAME_LENGTH }}
              ></TextField>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            className="input-field"
          >
            <TextField
              fullWidth
              type="text"
              name="email"
              label="Email"
              value={email}
              defaultValue={""}
              variant="outlined"
              error={emailError || emailAPIErrorMsg !== ""}
              onChange={handleChange}
              onBlur={handleInvalidEmail}
              sx={{ input: { color: emailError ? "#e71d36" : "#06d6a0" } }}
              helperText={
                emailError
                  ? "Invalid email"
                  : emailAPIErrorMsg !== ""
                  ? emailAPIErrorMsg
                  : null
              }
            ></TextField>
          </Grid>
          <Grid
            item
            xs={12}
            className="input-field"
          >
            <TextField
              fullWidth
              type="text"
              name="organisation"
              label="Organisation"
              value={organisation}
              variant="outlined"
              error={orgError}
              onChange={handleChange}
              sx={{
                input: { color: orgError ? "#e71d36" : "#06d6a0" },
              }}
              helperText={orgError ? en.alpha_numeric_only : null}
              inputProps={{ maxLength: CONSTANT.ORGANISATION_LENGTH }}
            ></TextField>
          </Grid>
          <Grid
            item
            xs={12}
            className="input-field"
          >
            <TextField
              fullWidth
              type="text"
              name="designation"
              label="Designation"
              value={designation}
              defaultValue={""}
              variant="outlined"
              error={designationError}
              onChange={handleChange}
              sx={{
                input: { color: designationError ? "#e71d36" : "#06d6a0" },
              }}
              helperText={designationError ? en.alpha_numeric_only : null}
              inputProps={{ maxLength: CONSTANT.DESIGNATION_LENGTH }}
            ></TextField>
          </Grid>

          <Grid
            item
            xs={12}
            className="input-field"
          >
            <TextField
              name="contact"
              type="text"
              variant="outlined"
              label="Contact number"
              error={contactError}
              defaultValue={""}
              helperText={contactError ? "Invalid contact" : null}
              value={contact}
              sx={{ input: { color: contactError ? "#e71d36" : "#06d6a0" } }}
              fullWidth
              inputProps={{ maxLength: CONSTANT.CONTACT_LENGTH }}
              onChange={handleChange}
            ></TextField>
          </Grid>
          <Grid
            item
            xs={12}
            className="input-field"
          >
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="password">Password</InputLabel>
              <OutlinedInput
                id="password"
                defaultValue={""}
                name="password"
                type={showPassword ? "text" : "password"}
                value={password}
                sx={{ color: "#06d6a0" }}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {!showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            className="input-field"
          >
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="confirm-password">
                Confirm Password
              </InputLabel>
              <OutlinedInput
                name="confirmPassword"
                defaultValue={""}
                id="confirm-password"
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={handleChange}
                error={passwordError}
                onBlur={handlePasswordError}
                sx={{ color: "#06d6a0" }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle confirm password visibility"
                      onClick={handleClicksetShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {!showConfirmPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm Password"
              />
              {passwordError ? (
                <FormHelperText error={true}>
                  Password do not match
                </FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%", padding: "16px" }}>
            <Button
              disabled={handleIsDisabled()}
              color="primary"
              variant="contained"
              onClick={handleRegister}
              sx={{ color: "#fff", padding: "0.5rem 2rem" }}
            >
              Register
            </Button>
          </Box>
        </Grid>
      </Box>
      <Box
        className="details"
        sx={{ backgroundColor: "#78e6dd", width: "100%", height: "100%" }}
      >
        <SideFirstScreen />
      </Box>
      {registrationIsLoading && (
        <ViraladLoader isloading={registrationIsLoading} />
      )}
      {registrationResponseRef.current ? (
        <Modal
          open={modalOpen}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              width: 400,
              padding: 16,
              boxShadow: 24,
              display: "flex",
              borderRadius: 8,
              alignItems: "center",
              position: "absolute",
              top: "50% !important",
              left: "50% !important",
              overflowWrap: "anywhere",
              justifyContent: "center",
              backgroundColor: "#ffffff",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                id="transition-modal-title"
                variant="h5"
                sx={{ textAlign: "center" }}
                component="h5"
              >
                <Box sx={{ fontWeight: 700 }}>Welcome, </Box>
                {registrationResponseRef.current.first_name}{" "}
                {registrationResponseRef.current.last_name}
              </Typography>
              <Typography
                id="transition-modal-description"
                sx={{ mt: 2, mb: 2, textAlign: "center" }}
              >
                Your Registration request has been sent to the Admin. Please
                wait for the approval for the same.
              </Typography>
              <Button variant="contained" onClick={handleModalClose}>
                TRY LOGIN
              </Button>
            </Box>
          </Box>
        </Modal>
      ) : null}
    </Box>
  );
};

export default Signup;
