import useAPICall from "../../../hooks/useAPICall";
import en from "../../../Lang/en.json";
import { GET_ROLES } from '../../../utilities/apis'

const useGetUserRoles = () => {
  const [data, error, isLoading, callGetData, setSuccessData, setError] =
    useAPICall(undefined, undefined);

  const defaultFallback = () => {
    setError(en.something_went_wrong);
    setSuccessData(undefined);
  };

  const statusObj = [
    {
      status_code: "200",
      status_txt: "OK",
      callBack: (res) => {
        const data = res.data;
        if (data && typeof data === "object") {
          setSuccessData(res.data);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_txt: "Bad Request",
      status_code: "400",
      callBack: defaultFallback,
    },
    {
      status_txt: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback
    }
  ];

  const getUserRoles = (body) => {
    const url = `${process.env.REACT_APP_API_URL}${GET_ROLES}`;
    callGetData({
      url,
      method: "GET",
      statusObj,
      defaultFallback,
      body,
    });
  };
  return [data, error, isLoading, getUserRoles, setSuccessData, setError];
};

export default useGetUserRoles;
