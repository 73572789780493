import React from "react";
import { Box, Typography } from "@mui/material";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";

const ErrorScreen = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <ReportGmailerrorredIcon fontSize={"large"} color="error" />
      <Typography
        variant="h2"
        sx={{
          fontFamily: "Montserrat-Bold",
          fontSize: 30,
        }}
      >
        Something went wrong
      </Typography>
      <Typography variant="body1" color="error" mt={2}>
        Brace yourself, Try Refreshing the page
      </Typography>
    </Box>
  );
};

export default ErrorScreen;
