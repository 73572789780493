import axios from "axios";
import en from "../../../Lang/en.json";
import { LOGIN } from "../../../utilities/apis";
import useAPICall from "../../../hooks/useAPICall";

const useLogin = () => {
  const [data, error, isLoading, callGetData, setSuccessData, setError] =
    useAPICall(undefined, "");

  const defaultFallback = (errorMsg = en.something_went_wrong) => {
    if (typeof errorMsg !== "string") {
      let res = errorMsg.errors.map((err) => {
        return [err["attr"], err["detail"], err["code"]];
      });
      setError(res);
    } else {
      setError(errorMsg);
    }
    setSuccessData(undefined);
  };

  const statusObj = [
    {
      status_code: "200",
      status_txt: "OK",
      callBack: (res) => {
        const data = res.data;
        if (data && typeof data === "object") {
          setSuccessData({ data: res.data });
          sessionStorage.setItem("authToken", res.data.token);
          axios.defaults.headers.common[
            "Authorization"
          ] = `token ${res.data.token}`;
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_txt: "Bad Request",
      status_code: "400",
      callBack: (res) => {
        defaultFallback(res.data);
      },
    },
    {
      status_txt: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const registerUser = (body) => {
    const url = `${process.env.REACT_APP_API_URL}${LOGIN}`;
    callGetData({
      url,
      method: "POST",
      statusObj,
      defaultFallback,
      body,
    });
  };
  return [data, error, isLoading, registerUser, setSuccessData, setError];
};

export default useLogin;
