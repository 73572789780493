import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Container,
  FormControl,
  IconButton,
  Input,
  Snackbar,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import logoTool2 from "../../../assets/images/logotool2.png";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [snackbarDetails, setSnackbarOpen] = useState({
    state: false,
    msg: "",
  });

  const handleInputChange = (e) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
    }
  };

  const handleSendResetLink = () => {};

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Container
      maxWidth="md"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <img width="200px" height={"auto"} src={logoTool2} alt="brand_logo" />

        <Box>
          <FormControl fullWidth sx={{ marginTop: "30px" }}>
            
            <Input
              placeholder="Enter your email"
              sx={{
                borderRadius: "8px",
                background: "#EDF2F7",
                "&:before": {
                  borderBottom: "none",
                },
                "&:hover:not(.Mui-disabled, .Mui-error):before": {
                  borderBottom: "none",
                },
              }}
              name="email"
              value={email}
              onChange={handleInputChange}
              variant="filled"
              inputProps={{
                style: {
                  padding: "12px 14px",
                  fontFamily: "Montserrat-Medium",
                },
              }}
            />
          </FormControl>

          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSendResetLink}
            sx={{
              boxShadow: "none",
              fontSize: "14px",
              fontFamily: "Montserrat-Medium",
              height: "46px",
              borderRadius: "8px",
              color: "#fff",
              marginTop: "24px",
            }}
          >
            Submit
          </Button>
        </Box>
      </Box>
      {/*isLoading && <VacBackdrop open={isLoading} />*/}
      {snackbarDetails.state && (
        <Snackbar
          open={snackbarDetails.state}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={snackbarDetails.msg}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleSnackbarClose}
            >
              <CloseOutlinedIcon fontSize="small" />
            </IconButton>
          }
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={"error"}
            sx={{ width: "100%" }}
          >
            {snackbarDetails.msg}
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default ForgetPassword;
