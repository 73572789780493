import React, { useEffect, useState } from "react";
import "./styles.scss";
import FilterOption from "../FilterOptions";
import { useNavigate } from "react-router-dom";
import PaletteIcon from "@mui/icons-material/Palette";
import UploadCreative from "../../../modules/uploadCreative";
import { Box, Button, Skeleton, Typography } from "@mui/material";
import useGetBrandOrCategories from "../../../modules/creatives/data/useGetProjectBrandOrCategories";

const SidePanel = ({
  setOffset,
  currentPath,
  setCurrentPage,
  filterInputData,
  setFilterInputData,
  getFilteredCreatives,
}) => {
  const navigate = useNavigate();
  const [filterObjects, setFilterObjects] = useState({});
  const [components, cError, cLoading, getComponents] =
    useGetBrandOrCategories();
  const [brands, bError, bLoading, getBrands] = useGetBrandOrCategories();
  const [categories, gError, gLoading, getCategories] =
    useGetBrandOrCategories();

  useEffect(() => {
    getBrands("brands");
    getComponents("components");
    getCategories("categories");
  }, []);

  useEffect(() => {
    setOffset(0);
    setFilterObjects({
      brands: brands,
      categories: categories,
      components: components,
    });
  }, [brands, categories, components]);

  return (
    <div className="leftnav">
      {currentPath && currentPath.includes("admin") && (
        <Box sx={{ flex: "0 1 auto" }}>
          <Typography
            sx={{
              fontFamily: "Montserrat-Bold",
              fontSize: 18,
              marginBottom: 1,
              marginTop: 1,
            }}
          >
            Explore ViralAds
          </Typography>
        </Box>
      )}
      {currentPath && currentPath.includes("user") && (
        <Button
          sx={{
            color: "#fff",
            flex: "0 1 auto",
            margin: "1rem 0",
            whiteSpace: "nowrap",
            fontFamily: "Montserrat-Medium",
          }}
          color="primary"
          variant="contained"
          startIcon={<PaletteIcon sx={{ color: "#fff" }} />}
          onClick={() =>
            navigate(
              currentPath === "/user/myproject/"
                ? "/user/explore"
                : "/user/myproject/",
              { replace: true, state: "" }
            )
          }
        >
          {currentPath === "/user/myproject/" ? "EXPLORE" : "MY PROJECTS"}
        </Button>
      )}
      {cError && bError && gError ? (
        "Something went wrong"
      ) : cLoading && bLoading && gLoading ? (
        <Box>
          {Array(3)
            .fill()
            .map((_, index) => (
              <Skeleton
                height={50}
                key={index}
                width={"100%"}
                animation="wave"
                variant="rectangular"
                sx={{ marginBottom: 0.5, borderRadius: "4px" }}
              />
            ))}
        </Box>
      ) : (
        <Box
          className="filterOptionWrapper"
          sx={{
            flex: "1 1 auto",
            overflow: "auto",
            borderRadius: "8px",
            marginBottom: "1rem",
            background: "#f0f8ff",
            border: "1px solid #b6daf9",
          }}
        >
          {Object.entries(filterObjects).map((filterObject) => {
            return (
              <FilterOption
                key={filterObject[0]}
                states={filterObject[1]}
                stateType={filterObject[0]}
                setStates={setFilterObjects}
                filterObjects={filterObjects}
                filterInputData={filterInputData}
                setFilterInputData={setFilterInputData}
              />
            );
          })}
        </Box>
      )}
      <UploadCreative
        setCurrentPage={setCurrentPage}
        getFilteredCreatives={getFilteredCreatives}
        filterInputData={filterInputData}
      />
    </div>
  );
};

export default SidePanel;
