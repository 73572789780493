import React from "react";
import { Outlet } from "react-router-dom";
import Navigation from "../../../components/user/navigation";

const UserLayout = () => {
  return (
    <div
      className="user-gp-layout"
      style={{
        display: "flex",
        flexFlow: "column",
        height: "100vh",
      }}
    >
      <Navigation />
      <Outlet />
    </div>
  );
};

export default UserLayout;
