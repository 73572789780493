import Select from "react-select";
import React from "react";
import './styles.scss'

const MultiSelect = ({ data, label, handleCategories }) => {
  return (
    <React.Fragment>
      <label htmlFor="brand_name" className="input-lable" >
        {label}
      </label>
      <Select
        isMulti
        className="multiSelect"
        onChange={handleCategories}
        closeMenuOnSelect={false}
        options={data}
      />
    </React.Fragment>
  );
};

export default MultiSelect;
