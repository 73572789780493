import useAPICall from "../../hooks/useAPICall";
import en from "../../Lang/en.json";
import axios from "axios";

const useDownloadCreative = () => {
  const [data, error, isLoading, callGetData, setSuccessData, setError] =
    useAPICall(undefined, "");

  const defaultFallback = () => {
    setError(en.something_went_wrong);
    setSuccessData(undefined);
  };

  const statusObj = [
    {
      status_code: "200",
      status_txt: "OK",
      callBack: (res) => {
        const data = res.data;
        setSuccessData(data);
        sessionStorage.setItem("userDetails", JSON.stringify(data));
      },
    },
    {
      status_txt: "Bad Request",
      status_code: 400,
      callBack: defaultFallback,
    },
    {
      status_txt: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const downloadCreative = (creativeId) => {
    const url = `${process.env.REACT_APP_API_URL}/viralad/user/download/${creativeId}/`;
    axios.defaults.headers.common[
      "Authorization"
    ] = `token ${sessionStorage.getItem("authToken")}`;
    callGetData({
      url,
      method: "GET",
      statusObj,
      defaultFallback,
    });
  };
  return [data, error, isLoading, downloadCreative, setSuccessData, setError];
};

export default useDownloadCreative;
