import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Typography } from '@mui/material';
import { Box } from '@mui/material';
import './styles.scss'

const FilterOption = (props) => {
  const {
    states,
    stateType,
    setStates,
    filterObjects,
    filterInputData,
    setFilterInputData,
  } = props;

  const handleChooseCCB = (e) => {
    let multipleComponents = [];

    multipleComponents = states.map((state) => {
      if (state.label === e.target.value) {
        return { ...state, checked: !state.checked };
      } else {
        return state;
      }
    });
    setStates({
      ...filterObjects,
      [stateType]: multipleComponents,
    });

    let type = stateType;
    let filteredStateDataString = filterInputData[type];

    let filteredStateDataArray = filteredStateDataString
      ? filteredStateDataString.split(",")
      : [];

    if (filteredStateDataArray.indexOf(e.target.value) === -1) {
      filteredStateDataArray.push(e.target.value);

      setFilterInputData({
        ...filterInputData,
        [e.target.name]: filteredStateDataArray.toString(),
      });
    } else {
      let temp = filteredStateDataArray.filter(
        (elem) => e.target.value !== elem
      );

      setFilterInputData({
        ...filterInputData,
        [e.target.name]: temp.toString(),
      });
    }
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography sx={{ fontFamily: "Montserrat-Medium" }}>
          {stateType.charAt(0).toUpperCase() + stateType.slice(1)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {states &&
          states.map((state) => {
            return (
              <Box
                className={`${stateType}-radio-btn selectBrandOption`}
                sx={{ width: "100%", display: "flex", marginBottom: 1 }}
                key={state.value}
              >
                <input
                  id={state.value}
                  type="checkbox"
                  checked={state.checked}
                  value={state.label}
                  name={stateType}
                  onChange={(e) => handleChooseCCB(e)}
                />
                <Typography htmlFor={state.value} variant="caption">
                  {state.label}
                </Typography>
              </Box>
            );
          })}
      </AccordionDetails>
    </Accordion>
  );
};

export default FilterOption;
