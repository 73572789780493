import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#723cb0",
    },
    secondary: {
      main: "#099998",
    },
    error: {
      main: "#e71d36",
    },
    warning: {
      main: "#ffca3a",
    },
    info: {
      main: "#4C6FFF",
    },
    success: {
      main: "#8ac926",
      dark: "#7bb222",
    },
  },
  typography: {
    fontFamily: [
      "Montserrat-Regular",
      "Montserrat-Medium",
      "Montserrat-SemiBold",
      "Montserrat-Bold",
      "Montserrat-ExtraBold",
    ].join(","),

    h1: {
      fontWeight: 600,
      color: "#393939",
      fontSize: "34px",
      fontFamily: "Montserrat-Regular",
    },
    h2: {
      fontSize: "28px",
      fontWeight: 600,
      color: "#393939",
      fontFamily: "Montserrat-Regular",
    },
    h5: {
      fontSize: "20px",
      color: "#393939",
      fontFamily: "Montserrat-SemiBold",
    },
    h6: {
      fontSize: "16px",
      color: "#393939",
      fontFamily: "Montserrat-SemiBold",
    },
    body1: {
      color: "#393939",
      fontFamily: "Montserrat-Regular",
    },
    body2: {
      color: "#393939",
      fontFamily: "Montserrat-Regular",
    },
    caption: {
      color: "#393939",
      fontFamily: "Montserrat-Regular",
    },
  },
});

export default theme;

export const tooltipTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: "Montserrat-Medium",
          fontSize: "10px",
          backgroundColor: "#4C6FFF",
        },
        arrow: {
          color: "#4C6FFF",
        },
      },
    },
  },
});

export const deleteIconButtontheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: "Montserrat-Medium",
          fontSize: "10px",
          backgroundColor: "#FFD3DF",
          color: "#E31D1C",
        },
        arrow: {
          color: "#FFD3DF",
        },
      },
    },
  },
});
