import useAPICall from "../../../hooks/useAPICall";
import en from "../../../Lang/en.json";
import { POST_VIRALADS } from "../../../utilities/apis";

const usePostViralAds = () => {
  const [data, error, isLoading, callGetData, setSuccessData, setError] =
    useAPICall(undefined, "");

  const defaultFallback = (res) => {
    if (res && res.data) {
      setError(res.data.errors[0].detail);
    } else {
      setError(en.something_went_wrong);
    }
    setSuccessData(undefined);
  };

  const statusObj = [
    {
      status_code: "200",
      status_txt: "OK",
      callBack: (res) => {
        const data = res.data;
        if (data && typeof data === "object") {
          setSuccessData(data);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_txt: "Bad Request",
      status_code: "400",
      callBack: (res) => defaultFallback(res),
    },
    {
      status_txt: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const postViralAds = (body) => {
    const url = `${process.env.REACT_APP_API_URL}${POST_VIRALADS}`;
    callGetData({
      url,
      method: "POST",
      statusObj,
      defaultFallback,
      body,
    });
  };
  return [data, error, isLoading, postViralAds, setSuccessData, setError];
};

export default usePostViralAds;
