import React from "react";
import { Box, Typography } from "@mui/material";
import emptyImage from "../../../assets/images/empty.jpg";

const ZeroData = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img src={emptyImage} alt="empty" width="250px" height="auto" />
      <Typography
        variant="body1"
        sx={{
          textAlign: "center",
          marginTop: 10,
          fontFamily: "Montserrat-Medium",
          color: "#555555",
        }}
      >
        There are zero templates to show currently
      </Typography>
    </Box>
  );
};

export default ZeroData;
