import React, { useState, useContext } from "react";
import "./style.scss";
import { logout } from "../../../utilities/functions";
import { UserDetailsContext, AuthStateContext } from "../../../App";
import logo from "../../../assets/images/logotool.png";
import {
  Avatar,
  Box,
  Container,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router-dom";
import * as URLS from "../../../utilities/urls";

const paperProps = {
  elevation: 0,
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
};

const Navigation = ({setUserInfoDrawerState}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const { userDetails } = useContext(UserDetailsContext);
  const { setIsAuthenticated } = useContext(AuthStateContext);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <header>
      <Container>
        <Box className="headwrap">
          <div className="logotop">
            <img src={logo} alt="logo" width={"100%"} />
          </div>

          {userDetails ? (
            <React.Fragment>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Tooltip title="Account settings">
                  <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
                    <Avatar
                      sx={{
                        background: "#00b4d8",
                        width: 32,
                        height: 32,
                        p: 0.5,
                      }}
                    >
                      {userDetails.first_name[0].toUpperCase()}
                      {userDetails.last_name[0].toUpperCase()}
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={paperProps}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem
                className="menu-item"
                onClick={() => {
                  handleClose();
                  setUserInfoDrawerState(true)
                }}
              >
                <PersonIcon sx={{ marginRight: 2 }} />{" "}
                {userDetails ? userDetails.first_name : null}{" "}
                {userDetails ? userDetails.last_name : null}
              </MenuItem>
              <MenuItem
                className="menu-item"
                onClick={() => {
                  handleClose();
                  navigate(`${URLS.ADMIN}/${URLS.MANAGE_USERS}`, {
                    replace: true,
                    state: "",
                  });
                }}
              >
                <PeopleIcon sx={{ marginRight: 2 }} /> All Users
              </MenuItem>
              <Divider />
              <MenuItem
                className="menu-item"
                sx={{ marginRight: 2 }}
                onClick={() => {
                  handleClose();
                  logout(setIsAuthenticated);
                }}
              >
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
            </React.Fragment>
          ) : null}
        </Box>
      </Container>
    </header>
  );
};

export default Navigation;
