import React, { useEffect } from "react";
import useUpdateMedia from "../data/useUpdateMedia";
import useDeleteMedia from "../data/useDeleteMedia";
import { Box, Typography } from "@mui/material";
import "./style.scss";

const ImageField = (props) => {
  const { media, format, setLoader, setPostResponseMessage } = props;
  const [data, error, isLoading, uploadMedia] = useUpdateMedia();
  const [deleteRes, delError, delIsLoading, deleteMedia] = useDeleteMedia();

  const handleFileUpload = (e) => {
    setLoader(true);
    let mediaType = e.target.files[0].type.split("/")[0];
    if (mediaType === "video") {
      videoinfo(media.width, media.height, media.id, e.target.files[0]);
    }
    if (mediaType === "image") {
      getMeta(media.width, media.height, media.id, e.target.files[0]);
    }
  };

  //  get video height width
  function videoinfo(w, h, id, currentMedia) {
    var video = document.createElement("video");
    video.preload = "metadata";
    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src);
      if (video.videoWidth === w && video.videoHeight === h) {
        let formData = new FormData();
        formData.append("media", currentMedia);
        let dataArray = ["media", id, formData];

        uploadMedia(dataArray);
      } else {
        setPostResponseMessage({
          message: "Image dimension is not correct",
          status: true,
          resStatus: 0,
        });
        setLoader(false);
      }
    };
    video.src = URL.createObjectURL(currentMedia);
  }

  useEffect(() => {
    if (data) {
      if (data.status) {
        setPostResponseMessage({
          message: "File uploaded successfully",
          status: true,
          resStatus: data.status,
        });
        setLoader(false);
      } else {
        setPostResponseMessage({
          message: "Error Uploading file! Try again ",
          status: true,
          resStatus: data.status,
        });
        setLoader(false);
      }
    }
  }, [data]);

  //  get image height width
  function getMeta(w, h, id, currentMedia) {
    let objectURL = URL.createObjectURL(currentMedia);
    const img = new Image();
    img.addEventListener("load", function () {
      if (this.naturalWidth === w && this.naturalHeight === h) {
        let formData = new FormData();
        formData.append("media", currentMedia);
        let dataArray = ["media", id, formData];

        uploadMedia(dataArray);
      }
    });
    img.src = objectURL;
  }

  const handleDeleteMedia = (eventID) => {
    setLoader(true);
    deleteMedia(eventID);
  };

  useEffect(() => {
    if (deleteRes) {
      setLoader(false);
    }
  }, [deleteRes]);

  return (
    <div className="img-element-wrapper">
      <Typography variant="body1" className="nameTitle">
        {media.name}
      </Typography>
      <div style={{ display: "flex" }}>
        <div className="holder-img">
          {(format === ".jpeg" || format === ".jpg" || format === ".png") && (
            <img
              src={media.media_src}
              width={"100%"}
              height={"100%"}
              alt="media-img"
            />
          )}
          {(format === ".mp4" || format === ".mov") && (
            <video width="100%" height="100%">
              <source src={media.media_src} type="video/mp4" />
            </video>
          )}
        </div>

        <div className="all-holder">
          <Typography variant="body2">SIZE</Typography>
          <Typography variant="caption">
            {media.width}px X {media.height}px
          </Typography>
          <Box className="upload-btn edit-btn">
            <input
              type="file"
              accept={format}
              onChange={handleFileUpload}
              data_list={media.id}
            />
            <Typography color="primary" variant="body2">
              EDIT
            </Typography>
          </Box>
          <div
            className="upload-btn delete-btn"
            onClick={() => {
              handleDeleteMedia(media.id);
            }}
          >
            <Typography color="error" variant="body2">
              DELETE
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageField;
