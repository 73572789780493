import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import usePostViralAds from "./data/usePostViralads";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { IconButton, Snackbar, Alert } from "@mui/material";
import ViraladLoader from "../../components/common/ViraladLoader";
import AdUploadDrawer from "../../components/common/AdUploadDrawer";
import { LIMIT } from "../creatives";

const UploadCreative = ({ setCurrentPage, getFilteredCreatives, filterInputData }) => {
  const [modalshow, setmodal] = useState(false);
  const [viralAdData, error, isloading, postViralAds] = usePostViralAds();
  const [showUploadmodal, setuploadmodal] = useState(false);
  const [snackbarDetails, setSnackbarDetails] = useState({
    message: "",
    status: false,
    severity: "",
  });

  function toggle() {
    setmodal(!modalshow);
  }

  function postDataTemplatefunction(
    template,
    thumbnail,
    brand_name,
    categoryList
  ) {
    setuploadmodal(true);
    let formData = new FormData();
    formData.append("template", template);
    formData.append("thumbnail", thumbnail);
    formData.append("brand_name", brand_name);
    formData.append("categories", categoryList);
    postViralAds(formData);
  }

  useEffect(() => {
    if (viralAdData && getFilteredCreatives) {
      setSnackbarDetails({
        message: `${viralAdData.name}, successfully Uploaded`,
        status: true,
        severity: "success",
      });
      setCurrentPage(1)
      // getFilteredCreatives(filterInputData, {
      //   limit: LIMIT,
      //   // offset: offset * LIMIT,
      // });
    } else if (error) {
      setSnackbarDetails({
        message: error,
        status: true,
        severity: "error",
      });
    }
    toggle();
  }, [viralAdData, error]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarDetails({ message: "", status: false, severity: "" });
  };

  const ShowUploadmodal = () => {
    setuploadmodal(true);
  };

  return (
    <React.Fragment>
      <div>
        <Button
          fullWidth
          color="success"
          variant="contained"
          className="modalparent"
          onClick={ShowUploadmodal}
          startIcon={<UploadFileIcon />}
          sx={{
            fontFamily: "Montserrat-Bold",
            color: "#fff",
            zIndex: 1000,
            marginBottom: 2,
          }}
        >
          Upload
        </Button>
        <Snackbar
          onClose={handleClose}
          autoHideDuration={6000}
          open={snackbarDetails.status}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <div>
            <Alert
              variant="filled"
              onClose={handleClose}
              sx={{ width: "100%" }}
              severity={snackbarDetails.severity ?? "success"}
              action={
                <IconButton size="small" color="inherit" onClick={handleClose}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              }
            >
              {snackbarDetails.message}
            </Alert>
          </div>
        </Snackbar>
      </div>
      {isloading && <ViraladLoader isloading={isloading} />}
      {showUploadmodal && (
        <AdUploadDrawer
          setuploadmodal={setuploadmodal}
          postDataTemplatefunction={postDataTemplatefunction}
        />
      )}
    </React.Fragment>
  );
};

export default UploadCreative;
