import React, { useState, useEffect } from "react";
import "../creatives/styles.scss";
import { useLocation } from "react-router-dom";
import {
  Box,
  Skeleton,
  Snackbar,
  Alert,
  IconButton,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import PaletteIcon from "@mui/icons-material/Palette";

// ------------- Import Components ------------------//
import useDeleteViralad from "../creatives/data/useDeleteViralad";
import ZeroData from "../../components/common/ZeroData";
import DeleteValidation from "../../components/common/DeleteValidation";
import CreativeCard from "./../../components/common/CreativeCard/index";
import PinterestStack from "../creatives/PinterestStack";
import ErrorScreen from "../../components/common/ErrorScreen";
import UploadCreative from "../uploadCreative";

const UserCreatives = (props) => {
  const navigate = useNavigate();
  const {
    handleViewCreative,
    templatesData,
    getTemplatesData,
    errorTemplateData,
    isLoading,
  } = props;
  const location = useLocation();
  const [creativeId, setCreativeId] = useState();
  const [currentPath, setCurrentPath] = useState();
  const [deleteResponse, deleteError, deleteIsLoading, deleteViralad] =
    useDeleteViralad();
  const [deleteModalState, setDeleteModalState] = useState(false);
  const [snackbarDetails, setSnackbarDetails] = useState({
    message: "",
    status: false,
    severity: "",
  });

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [deleteIsLoading, deleteError]);

  useEffect(() => {
    if (deleteResponse) {
      setSnackbarDetails({
        message: `Creative Deleted Successfully`,
        status: true,
        severity: "success",
      });
    }
  }, [deleteResponse]);

  const handleModalClose = () => {
    setDeleteModalState(false);
  };

  const deleteTemplate = () => {
    setDeleteModalState(false);
    let dataArray = ["template", creativeId];
    deleteViralad(dataArray);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarDetails({ message: "", status: false, severity: "" });
  };

  return (
    <section className="adminBody">
      <Box className="adminBodyWrapper">
        <div className="leftnav">
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Button
              sx={{
                color: "#fff",
                flex: "0 1 auto",
                margin: "1rem 0",
                whiteSpace: "nowrap",
                fontFamily: "Montserrat-Medium",
              }}
              color="primary"
              variant="contained"
              startIcon={<PaletteIcon sx={{ color: "#fff" }} />}
              onClick={() =>
                navigate("/user/explore", { replace: true, state: "" })
              }
            >
              {currentPath === "/user/myproject/" ? "EXPLORE" : "MY PROJECTS"}
            </Button>
            <UploadCreative
              getFilteredCreatives={getTemplatesData}
              filterInputData={templatesData}
            />
          </Box>
        </div>
        {errorTemplateData ? (
          <ErrorScreen />
        ) : isLoading ? (
          <Box sx={{ height: "100%", width: "100%", paddingLeft: "1rem" }}>
            <PinterestStack className="right_content">
              {Array(4)
                .fill()
                .map((_, index) => (
                  <Skeleton
                    key={index}
                    width={"80%"}
                    height={"100%"}
                    variant="rectangular"
                    className="pinterest_card small"
                    style={{
                      margin: "10px",
                      border: "none!important",
                      backgroundColor: "rgba(0,0,0,0.3)",
                    }}
                    sx={{ borderRadius: "8px", margin: "0 0.25rem" }}
                  />
                ))}
            </PinterestStack>
          </Box>
        ) : templatesData && templatesData.length !== 0 ? (
          <Box
            sx={{
              height: "100%",
              width: "100%",
              paddingLeft: "1rem",
            }}
          >
            <PinterestStack className="right_content">
              {templatesData.map((template, i) => {
                return (
                  <CreativeCard
                    key={i}
                    template={template}
                    currentPath={currentPath}
                    setCreativeId={setCreativeId}
                    handleViewCreative={handleViewCreative}
                    setDeleteModalState={setDeleteModalState}
                  />
                );
              })}
            </PinterestStack>
          </Box>
        ) : (
          <ZeroData />
        )}
        {deleteModalState && (
          <DeleteValidation
            modalState={deleteModalState}
            handleModalClose={handleModalClose}
            deleteTemplate={deleteTemplate}
          />
        )}
        <Snackbar
          onClose={handleClose}
          autoHideDuration={6000}
          open={snackbarDetails.status}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <div>
            <Alert
              variant="filled"
              onClose={handleClose}
              sx={{ width: "100%" }}
              severity={snackbarDetails.severity ?? "success"}
              action={
                <IconButton size="small" color="inherit" onClick={handleClose}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              }
            >
              {snackbarDetails.message}
            </Alert>
          </div>
        </Snackbar>
      </Box>
    </section>
  );
};

export default UserCreatives;
