export const REGISTER = "/register";
export const LOGIN = "/login";
export const FORGOT_PASSWORD = "/forgot_password"
export const RESET_PASSWORD = "reset_password"

export const HOME = "/home";

export const ADMIN = "/admin";
export const ADMIN_TEMPLATE = "templates";
export const MANAGE_USERS = "manage_users";

export const USER = "/user";
export const USER_HOME = "home";
export const USER_EXPLORE = "explore";

export const MYPROJECT = "myproject";
export const VIEW = "view";
export const EDIT = "edit";
export const USER_PROFILE = "user_profile";
