import React, { useState } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import WindowIcon from "@mui/icons-material/Window";
import WindowOutlinedIcon from "@mui/icons-material/WindowOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";

import "./styles.scss";

const CreativeCard = (props) => {
  const {
    template,
    currentPath,
    setCreativeId,
    handleViewCreative,
    setDeleteModalState,
  } = props;

  const navigate = useNavigate();

  const handleDeleteTemplate = (id) => {
    setDeleteModalState(true);
    setCreativeId(id);
  };

  const [isCategoryActive, setIsCategoryActive] = useState(true);
  const [isComponentActive, setIsComponentActive] = useState(false);
  const [template_detail, setTemplateDetail] = useState();

  const handleComponentEnter = () => {
    setIsComponentActive(true);
    setTemplateDetail(template?.components);
  };

  const handleCategory = () => {
    setIsCategoryActive(true);
    setTemplateDetail(template?.categories);
  };

  const handleComponentLeave = () => {
    setIsComponentActive(false);
    setTemplateDetail();
  };

  const handleCategoryLeave = () => {
    setIsCategoryActive(false);
    setTemplateDetail();
  };

  const handleEditCreative = (template) => {
    // Use the URLSearchParams API to parse the URL
    // Create a URL object
    var url = new URL(template?.iframe_url);

    // Get the value of the 'adloc' parameter from the query string
    var adlocValue = url.searchParams.get("adloc");

    if (adlocValue) {
      navigate(`/edit/${template?.name}/${adlocValue}`, {
        state: template?.id,
        replace: true,
      });
    } else {
      console.log("adloc parameter not found in the URL");
    }
  };

  return (
    <div
      className={`${
        template?.height <= 300
          ? "small"
          : template?.height <= 480
          ? "large"
          : "large"
      } pinterest_card`}
    >
      <Typography variant="h6" color="secondary" className="title-card">
        {template?.name}
      </Typography>
      <div className="card_img">
        {template?.frames && (
          <img className="img-card" src={template?.thumbnail} alt="thumbnail" />
        )}
      </div>

      <div className="template_icons">
        <div className="template_details_icons">
          <Tooltip
            TransitionComponent={Zoom}
            title="Categories"
            placement="bottom"
            arrow
          >
            {isCategoryActive ? (
              <div
                className="thumbnail_icon"
                onMouseOver={handleCategory}
                onMouseLeave={handleCategoryLeave}
              >
                <WindowIcon color="info" />
              </div>
            ) : (
              <div
                className="thumbnail_icon "
                onMouseOver={handleCategory}
                onMouseLeave={handleCategoryLeave}
              >
                <WindowOutlinedIcon color="info" />
              </div>
            )}
          </Tooltip>
          <Tooltip
            TransitionComponent={Zoom}
            title="Components"
            placement="bottom"
            arrow
          >
            {isComponentActive ? (
              <div
                className="thumbnail_icon"
                onMouseOver={handleComponentEnter}
                onMouseLeave={handleComponentLeave}
              >
                <SettingsIcon color="info" />
              </div>
            ) : (
              <div
                className="thumbnail_icon"
                onMouseOver={handleComponentEnter}
                onMouseLeave={handleComponentLeave}
              >
                <SettingsOutlinedIcon color="info" />
              </div>
            )}
          </Tooltip>
        </div>
        <div className="template_details_icons">
          <div className="thumbnail_icon">
            <Tooltip
              TransitionComponent={Zoom}
              title="Edit"
              placement="left"
              arrow
            >
              <EditIcon
                onClick={() => {
                  handleEditCreative(template);
                }}
                color="secondary"
              />
            </Tooltip>
          </div>
          <div className="thumbnail_icon">
            <Tooltip
              TransitionComponent={Zoom}
              title="View"
              placement="top"
              arrow
            >
              <RemoveRedEyeOutlinedIcon
                onClick={() => {
                  handleViewCreative(template);
                }}
                color="secondary"
              />
            </Tooltip>
          </div>
          {JSON.parse(sessionStorage.getItem("userDetails")).id ===
            template?.user && (
            <div className="thumbnail_icon">
              <Tooltip
                TransitionComponent={Zoom}
                title="Delete"
                placement="right"
                arrow
              >
                <DeleteOutlinedIcon
                  color="error"
                  onClick={() => {
                    handleDeleteTemplate(template?.id);
                  }}
                />
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      {template_detail && (
        <div className="template_details">
          {template_detail.split(",").map((category, i) => (
            <Typography variant="caption" key={i} className="template_capsule">
              {category}
            </Typography>
          ))}
        </div>
      )}
    </div>
  );
};

export default CreativeCard;
