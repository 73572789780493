import {
  Alert,
  Box,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState, createContext, useCallback } from "react";
import Fieldwrap from "../../modules/Containers/ElementWrapper";
import EditNav from "../../modules/Containers/EditNav";
import publishBtn from "../../assets/images/publishBtn.svg";
import useGetTemplate from "./data/useGetTemplateById";
import useGetDownloadTemplate from "./data/useGetDownloadTemplateById";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import "./editTemplate.scss";

export const TemplateDataContext = createContext();

const Edit = () => {
  const [projectData, setProjectData] = useState();
  const [iframeDim, setIframeDim] = useState({ width: 0, height: 0 });
  const [activeFrame, setActiveFrame] = useState();
  const [iframeUrl, setFrameUrl] = useState();
  const [reloadingState, setReloadingState] = useState();
  const [refreshIframe, setRefreshIframe] = useState(0);
  const [sharableLink, setSharableLink] = useState("");
  const [postResponseMessage, setPostResponseMessage] = useState({
    message: "",
    status: false,
  });
  const [templateData, templateError, templateIsLoading, getTemplatebyId] =
    useGetTemplate();
  const [
    downloadData,
    downloadError,
    downloadIsLoading,
    getDownloadTemplatebyId,
  ] = useGetDownloadTemplate();

  const { state } = useLocation();
  const ldPath = useLocation();

  const refreshIframeHandler = useCallback((dataIframe_url) => {
    setRefreshIframe(Math.random() * 100);
    if (!!document.getElementById("project-preview")) {
      document.getElementById("project-preview").src = dataIframe_url;
    }
  }, []);

  useEffect(() => {
    getTemplatebyId(state);
  }, []);

  useEffect(() => {
    if (templateData) {
      setProjectData(templateData);
      setIframeDim({
        width: templateData.width,
        height: templateData.height,
      });
      setActiveFrame(templateData.frames[0]);
      setFrameUrl(templateData.iframe_url);
      refreshIframeHandler(templateData.iframe_url);

      var url = templateData.iframe_url;
      var sparams = url.split("?")[1];
      const params = sparams.split("&");
      const values = params.map((param) => {
        return param.split("=")[1];
      });
      let link = `/viralad/${templateData.name}?adloc=${values[0]}&width=${values[1]}&height=${values[2]}`;
      console.log(link);
      setSharableLink(link);
    }
  }, [templateData, state, reloadingState, iframeUrl, refreshIframeHandler]);

  const NewTabOpenLink = (downloadIink) => {
    window.open(downloadIink, "_blank");
  };

  function onDismiss(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    setReloadingState({});
    setPostResponseMessage({
      message: "",
      status: false,
    });
  }

  useEffect(() => {
    if (downloadData) {
      NewTabOpenLink(downloadData);
      if (downloadData.status) {
        setPostResponseMessage({
          message: "Download Successful",
          status: true,
        });
      } else {
        setPostResponseMessage({
          message: "Download failed ",
          status: true,
        });
      }
    }
  }, [downloadData]);

  const downloadTemp = () => {
    let path = ldPath.pathname.split("/").at(-2);
    getDownloadTemplatebyId(path);
  };

  return (
    <TemplateDataContext.Provider value={{ projectData }}>
      <section className="edit-template-wrapper">
        {templateIsLoading || downloadIsLoading ? (
          <CircularProgress color="inherit" />
        ) : (
          <React.Fragment>
            <div className="edit-nav-fields-wrapper">
              <EditNav
                activeFrame={activeFrame}
                setActiveFrame={setActiveFrame}
              />
              <Fieldwrap
                setReloadingState={setReloadingState}
                activeFrame={activeFrame}
              />
            </div>
            <Box className="frame-wrapper">
              <Box className="eye-btn-wrapper">
                <Tooltip title="Refresh" arrow placement="top">
                  <IconButton
                    color="primary"
                    onClick={() => refreshIframeHandler(iframeUrl)}
                  >
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>

                <Button
                  variant="text"
                  component="a"
                  target="_blank"
                  href={sharableLink}
                  startDecorator={<OpenInNewIcon />}
                >
                  Open in new tab
                </Button>
              </Box>
              <iframe
                key={refreshIframe}
                id="project-preview"
                src={iframeUrl ? iframeUrl : ""}
                title={"frame_img"}
                style={{
                  width: iframeDim.width + 40,
                  height: iframeDim.height,
                }}
              ></iframe>
              <Box className="publish-btn-container">
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={downloadTemp}
                >
                  Publish
                </Button>
              </Box>
            </Box>
          </React.Fragment>
        )}

        {postResponseMessage.status && (
          <Alert
            severity="success"
            onClose={onDismiss}
            action={
              <IconButton size="small" color="inherit" onClick={onDismiss}>
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          >
            {postResponseMessage.message}
          </Alert>
        )}
      </section>
    </TemplateDataContext.Provider>
  );
};

export default Edit;
