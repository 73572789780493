import React from "react";
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import defaultImg from "../../../../assets/images/default.png";
import "./style.scss";

const CarouselModal = (props) => {
   const { setimage, file, index, removeImageHandler } = props;

   return (
      <div className="imgbox">
         <h6>{file.name}</h6>
         <div className="imgHolder">
            <div className="uploaded-img">
               <img
                  src={file.name === "Upload" ? defaultImg : file.img}
                  alt="sample"
               />
               {file.name === "Upload" ? (
                  <BackupOutlinedIcon className="upsvg" />
               ) : (
                  <CancelRoundedIcon
                     className="closeUpload"
                     onClick={() => removeImageHandler(index)}
                  />
               )}
               <input
                  type="file"
                  onChange={(e) => setimage(e, index)}
                  className="uploadedImg"
               />
            </div>
         </div>
      </div>
   );
};

export default CarouselModal;
