// ----------- Import Packages ---------------------//
import React from "react";
import { Container, Grid, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

// ------------ Import Assets ---------------------//
import explore from "../../../assets/images/explore.png";
import myproject from "../../../assets/images/myproject.png";

// ------------ Import Styles --------------------//
import "./styles.scss";

const UserHome = () => {
  const navigate = useNavigate();
  return (
    <div className="slid-menu">
      <Container className="wrap-menu">
        <Grid container sx={{ height: "100%" }}>
          <Grid item xs={6} sx={{ borderRight: "2px solid pink" }}>
            <div className="explore-div text-center">
              <img src={explore} alt="" className="d-block m-auto" />
              <div>
                <Button variant="contained" size="large">
                  <Link
                    to="/user/explore/"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    Explore
                  </Link>
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={6} sx={{ borderLeft: "2px solid pink" }}>
            <div className="explore-div text-center ">
              <img src={myproject} alt="" className="d-block m-auto" />
              <div>
                <Button
                  sx={{ whiteSpace: "nowrap" }}
                  variant="contained"
                  size="medium"
                  onClick={() =>
                    navigate("/user/myproject/", { replace: true, state: "" })
                  }
                >
                  My Projects
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default UserHome;
