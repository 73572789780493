//------------------- Import Packages --------------------//
import { Navigate } from "react-router-dom";
//----------------- Import HOC Component -----------------//
import PublicLayout from "./hoc/PublicLayout";
import ProtectedUserLayout from "./hoc/ProtectedLayout/users";
import ProtectedAdminLayout from "./hoc/ProtectedLayout/admin";
//----------------- Import Component ---------------------//
import Error404 from "./pages/Error";
import Login from "./pages/public/Auth/Login";
import ParallaxView from "./ParallaxView";
import UserHome from "./pages/users/home";
import Explore from "./pages/explore";
import Register from "./pages/public/Auth/Register";
import ManageUsers from "./pages/admin/manageUsers";
import ViraladLoader from "./components/common/ViraladLoader";
import MyProjects from "./pages/users/myProjects";
import Edit from "./pages/edit";
import ForgetPassword from "./pages/public/Auth/ForgotPassword";
import * as URLS from "./utilities/urls";
import ResetPassword from "./pages/public/Auth/ResetPassword";

const routes = (isAuthenticated, userDetails) => [
  {
    path: "/",
    element: isAuthenticated ? (
      userDetails ? (
        userDetails.is_admin ? (
          <ProtectedAdminLayout />
        ) : (
          <Navigate to="/user/explore" />
        )
      ) : (
        <ViraladLoader />
      )
    ) : (
      <Navigate to="/login" />
    ),
    children: [
      { path: "/admin/home", element: <Explore /> },
      { path: "/admin/manage_users", element: <ManageUsers /> },
      { path: "/edit/:name/:id", element: <Edit /> },
    ],
  },
  {
    path: "/",
    element: isAuthenticated ? (
      userDetails ? (
        !userDetails.is_admin ? (
          <ProtectedUserLayout />
        ) : (
          <Navigate to="/admin/home" />
        )
      ) : (
        <ViraladLoader />
      )
    ) : (
      <Navigate to="/login" />
    ),
    children: [
      { path: "/user/home", element: <UserHome /> },
      { path: "/user/explore", element: <Explore /> },
      { path: "/user/myproject", element: <MyProjects /> },
      { path: "/edit/:name/:id", element: <Edit /> },
    ],
  },
  {
    // PUBLIC
    path: "/",
    element: !isAuthenticated ? (
      <PublicLayout />
    ) : userDetails ? (
      !userDetails.is_admin ? (
        <Navigate to="/user/explore" />
      ) : (
        <Navigate to="/admin/home" />
      )
    ) : (
      <ViraladLoader />
    ),
    children: [
      { path: URLS.LOGIN, element: <Login /> },
      { path: URLS.REGISTER, element: <Register /> },
      { path: URLS.FORGOT_PASSWORD, element: <ForgetPassword /> },
      { path: URLS.RESET_PASSWORD, element: <ResetPassword /> },
    ],
  },
  {
    path: "viralad/:name",
    element: <ParallaxView />,
  },
  {
    path: "*",
    element: <Error404 />,
  },
];

export default routes;
