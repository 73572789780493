import React, { useState, useRef, useEffect } from "react";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditIcon from "@mui/icons-material/Edit";
import useUpdateCtaUrl from "../data/useUpdateCtaUrl";
import { Box, IconButton, TextField, Tooltip, Typography } from "@mui/material";

const CtaUrls = (props) => {
  const { cta_urls, index, setLoader, setPostResponseMessage } = props;
  const urlarea = useRef();
  const [inputText, setInputText] = useState(cta_urls.url);
  const [data, error, isLoading, updateCtaUrl] = useUpdateCtaUrl();

  const textareafun = (e) => {
    urlarea.current.disabled = false;
  };

  const url_validation = (e) => {
    setInputText(e.target.value);
  };

  const cta_urls_Upadte = () => {
    let res = inputText.match(
      "((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)"
    );
    urlarea.current.disabled = true;
    if (res !== null) {
      setLoader(true);
      let ctaurls = [cta_urls && cta_urls.id, inputText];
      updateCtaUrl(ctaurls);
    } else {
      alert("Invalid URL");
    }
  };

  useEffect(() => {
    setLoader(false);
    if (data) {
      if (data.status) {
        setPostResponseMessage({
          message: "Link Uploaded",
          status: true,
          resStatus: data.status,
        });
      } else {
        setPostResponseMessage({
          message: "Link Uploaded Fail",
          status: true,
          resStatus: data.status,
        });
      }
    }
  }, [data]);

  return (
    <div className="text-field">
      <Typography className="text-heading" variant="body1">
        CTA Url: {index + 1}
      </Typography>
      <Box className="input-elements-wrapper">
        <TextField
          fullWidth
          multiline
          type="url"
          value={inputText}
          onChange={url_validation}
          onBlur={cta_urls_Upadte}
          ref={urlarea}
          disabled
        ></TextField>
        <Box className="action-btn-wrapper">
          <Tooltip title="Edit" placement="top" arrow>
            <IconButton onClick={textareafun} color="primary">
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" placement="bottom" arrow>
            <IconButton color="error">
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <div className="saving"></div>
    </div>
  );
};

export default CtaUrls;
