import "./style.scss";
import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import AdminNav from "../../../components/admin/navigation";
import { Container, Drawer } from "@mui/material";
import UserProfile from "../../../components/common/UserProfile";

const AdminLayout = () => {
  const [userInfoDrawerState, setUserInfoDrawerState] = useState(false);
  const handleDrawerClose = () => setUserInfoDrawerState(false);

  return (
    <React.Fragment>
      <div className="admin-gp-layout">
        <AdminNav setUserInfoDrawerState={setUserInfoDrawerState} />
        <Outlet />
        <Drawer
          anchor="right"
          open={userInfoDrawerState}
          onClose={handleDrawerClose}
        >
          <UserProfile />
        </Drawer>
      </div>
    </React.Fragment>
  );
};

export default AdminLayout;
