import { Typography } from '@mui/material';
import React from 'react'

const RadioButton = (props) => {
	const {index, activeFrame, frameid, handleFrameSelect} = props;
	return (
		<li
			onClick={() => {
				handleFrameSelect(frameid);
			}}
			className={
				activeFrame && frameid === activeFrame.id
					? "listab activeli"
					: "listab"
			}
			id={`f${index}`}
		>
			<Typography variant='body2'>{index + 1}</Typography>
		</li>
	);
};

export default RadioButton