import React, { useState } from "react";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { InputLabel } from "@mui/material";

const UserDetails = ({
  user,
  status,
  setStatus,
  statusData,
  handleRole,
  setUpdatedStatus,
}) => {
  const [updateDisabled, setUpdateDisabled] = useState(true);

  function handleUserUpdate(userId) {
    let formData = new FormData();
    formData.append("status", status);
    setUpdatedStatus(userId, formData);
  }

  function handleStatusChange(e) {
    setStatus(e.target.value);
    setUpdateDisabled(false);
  }

  return (
    <TableRow
      key={user.id}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
      }}
    >
      <TableCell align="left" component="th" scope="user">
        {user.id}
      </TableCell>
      <TableCell align="left">{user.email}</TableCell>
      <TableCell align="left">{user.first_name}</TableCell>
      <TableCell align="left">{user.last_name}</TableCell>
      <TableCell align="left">{user.organisation}</TableCell>
      <TableCell align="left">{user.designation}</TableCell>
      <TableCell align="left">{user.phone_number}</TableCell>
      <TableCell align="left">
        {
          <FormControl sx={{ minWidth: 120 }} size="small">
            <InputLabel>Status</InputLabel>
            <Select
              label="Status"
              value={status}
              id="demo-select-small"
              labelId="demo-select-small"
              defaultValue={user.status}
              onChange={handleStatusChange}
            >
              {statusData
                ? statusData.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.key}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
        }
      </TableCell>
      <TableCell align="left">{handleRole(user.role)}</TableCell>
      <TableCell align="left">
        <Button
          variant="contained"
          disabled={updateDisabled}
          onClick={() => handleUserUpdate(user.id)}
        >
          UPDATE
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default UserDetails;
