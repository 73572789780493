import React, { useState, useRef, useEffect } from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import useUpdateMedia from "../data/useUpdateMedia";
import useDeleteMedia from "../data/useDeleteMedia";
import { Box, IconButton, Typography } from "@mui/material";
import "../textFields.scss";

const TextField = (props) => {
  const { text, activeFrame, index, setLoader, setPostResponseMessage } = props;
  const textarea = useRef();
  const [inputText, setInputText] = useState();
  const [uploadNow, setUploadNow] = useState(false);
  const [data, error, isLoading, uploadMedia] = useUpdateMedia();
  const [deleteRes, deleteErr, deleteIsLoading, deleteTextMedia] =
    useDeleteMedia();

  useEffect(() => {
    setInputText(text.content);
  }, [activeFrame, text]);

  const textareafun = (e) => {
    textarea.current.disabled = false;
    setUploadNow(true);
  };

  const deleteText = (id) => {
    let dataArray = ["text", id];
    deleteTextMedia(dataArray);
  };

  useEffect(() => {
    alert(data);
  }, [deleteRes]);

  const grabtext = () => {
    textarea.current.disabled = true;

    const json = {
      id: text.id,
      content: inputText,
      text_id: text.text_id,
    };

    let dataArray = ["text", text.id, json];
    if (uploadNow) {
      setLoader(true);
      uploadMedia(dataArray);
    }
  };

  useEffect(() => {
    if (data) {
      if (data.status) {
        setPostResponseMessage({
          message: "Data uploaded successfully",
          status: true,
          resStatus: data.status,
        });
        setLoader(false);
        setUploadNow(false);
      } else {
        setPostResponseMessage({
          message: "Error Uploading Data! Try again",
          status: true,
          resStatus: data.status,
        });
        setLoader(false);
        setUploadNow(false);
      }
    }
  }, [data]);

  return (
    <Box className="text-field">
      <Typography className="text-heading" variant="body1">TEXT : {index + 1}</Typography>
      <div className="input-elements-wrapper">
        <TextField
          multiline
          type="text"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          onMouseLeave={grabtext}
          ref={textarea}
          disabled
        ></TextField>
        <Box className="action-btn-wrapper">
          <IconButton onClick={textareafun} color="primary">
            <EditOutlinedIcon />
          </IconButton>
          <IconButton
            color="primary"
            onClick={() => {
              deleteText(text.id);
            }}
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </Box>
      </div>
      <div className="saving">
        <span>Word limit: {text.content.length}</span>{" "}
      </div>
    </Box>
  );
};

export default TextField;
