import React from "react";
import { Link } from "react-router-dom";
import empty from "../../assets/images/empty.jpg";
import { Container, Typography } from "@mui/material";

const Error = () => {
  return (
    <div>
      <Container
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h1"
          className="display-3"
          style={{ fontFamily: "Montserrat-Bold", marginBottom: "0.5rem" }}
        >
          Error 404!
        </Typography>
        <Typography variant="h5">Page Not Found</Typography>
        <img width={300} height={300} src={empty} alt="vector" />
        <Typography variant="body1" className="lead">
          Goto <Link to="/">Home</Link>
        </Typography>
      </Container>
    </div>
  );
};

export default Error;
