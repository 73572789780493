import { Box, Button, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import logo from "../../../assets/images/logotool2.png";
import { useLocation, useNavigate } from "react-router-dom";

const SideFirstScreen = () => {
  const [isRegisterPage, setIsRegisterPage] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setIsRegisterPage(location.pathname.includes("register"));
  }, [location]);

  return (
    <Box
      sx={{
        bg: "#f1f1f1",
        width: "100%",
        height: "100%",
        display: "flex",
        padding: "3rem",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <img src={logo} alt="logo" />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 3,
        }}
      >
        {isRegisterPage ? (
          <React.Fragment>
            <Typography variant="body1">Already have an account? </Typography>
            <Button
              onClick={() => navigate("/login", { replace: true, state: "" })}
              color="warning"
              variant="contained"
              sx={{ marginLeft: 3 }}
            >
              LOGIN
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography variant="body1">Create new account? </Typography>
            <Button
              onClick={() =>
                navigate("/register", { replace: true, state: "" })
              }
              color="info"
              variant="contained"
              sx={{ marginLeft: 3 }}
            >
              REGISTER
            </Button>
          </React.Fragment>
        )}
      </Box>
    </Box>
  );
};

export default SideFirstScreen;
