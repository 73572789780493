import en from "../../../Lang/en.json";
import useAPICall from "../../../hooks/useAPICall";
import { REGISTER } from "../../../utilities/apis";

const useRegisterUser = () => {
  const [data, error, isLoading, callGetData, setSuccessData, setError] =
    useAPICall(undefined, "");

  const defaultFallback = (errorMsg = en.something_went_wrong) => {
    if (typeof errorMsg !== "string") {
      let res = errorMsg.errors.map((err) => {
        return [err["attr"], err["detail"]];
      });
      setError(res);
    }
    setSuccessData(undefined);
  };

  const statusObj = [
    {
      status_code: "201",
      status_txt: "Created",
      callBack: (res) => {
        const data = res.data;
        if (data && typeof data === "object") {
          setSuccessData({ data: res.data });

        } else {
          defaultFallback();
        }
      },
    },
    {
      status_txt: "Bad Request",
      status_code: "400",
      callBack: (res) => {
        defaultFallback(res.data);
      },
    },
    {
      status_txt: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const registerUser = (body) => {
    const url = `${process.env.REACT_APP_API_URL}${REGISTER}`;
    callGetData({
      url,
      method: "POST",
      statusObj,
      defaultFallback,
      body,
    });
  };
  return [data, error, isLoading, registerUser, setSuccessData, setError];
};

export default useRegisterUser;
