import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Grid,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button,
  Snackbar,
  Alert,
  Link,
} from "@mui/material";
import { validator } from "../../../utilities/functions";
import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { AuthStateContext, UserDetailsContext } from "../../../App";
import ViraladLoader from "../../../components/common/ViraladLoader";
import SideFirstScreen from "../../../components/common/SideFirstScreen";
import useLogin from "../data/useLogin";
import * as URLS from "../../../utilities/urls";

const Signup = () => {
  const { setIsAuthenticated } = useContext(AuthStateContext);
  const { setUserDetails } = useContext(UserDetailsContext);
  const navigate = useNavigate();
  const [loginSuccessResponse, loginError, loginIsLoading, loginUser] =
    useLogin();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");
  const handleChange = (e) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
    } else if (e.target.name === "password") {
      setPassword(e.target.value);
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLogin = () => {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    loginUser(formData);
  };

  useEffect(() => {
    if (loginSuccessResponse) {
      setIsAuthenticated(true);
      setUserDetails(loginSuccessResponse.data.user);
    } else if (loginError) {
      setIsAuthenticated(false);
    }
  }, [loginSuccessResponse]);

  useEffect(() => {
    if (typeof loginError !== "string") {
      loginError.forEach((err) => {
        setErrorMsg(err[1]);
      });
      setIsAuthenticated(false);
    } else {
      setErrorMsg(loginError);
    }
  }, [loginError]);

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorMsg("");
  };

  const handleInvalidEmail = () => {
    setEmailError(!validator(email, "email"));
  };

  const handleIsDisabled = () => {
    let isDisabled = email.length === 0 || emailError;
    return isDisabled;
  };

  return (
    <Box className="signup">
      <Box className="form" sx={{ padding: 4 }}>
        <Typography className="form_topic" variant="h4">
          Sign In to your account!
        </Typography>
        <Grid container spacing={2} direction="row" className="input-fields">
          <Grid
            item
            xs={12}
            // classes={{ item: classes.gridItem }}
            className="input-field"
          >
            <TextField
              name="email"
              type="text"
              variant="outlined"
              label="Email"
              error={emailError}
              onBlur={handleInvalidEmail}
              value={email}
              fullWidth
              helperText={emailError ? "Invalid email" : null}
              sx={{ input: { color: "#06d6a0" } }}
              onChange={handleChange}
            ></TextField>
          </Grid>
          <Grid
            // classes={{ item: classes.gridItem }}
            item
            xs={12}
            className="input-field"
          >
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="password">Password</InputLabel>
              <OutlinedInput
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                value={password}
                sx={{ color: "#06d6a0" }}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {!showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
          </Grid>
          <Box
            sx={{
              width: "100%",
              padding: "16px",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Link
              onClick={(e) => {
                e.preventDefault();
                navigate(URLS.FORGOT_PASSWORD, { replace: true, state: "" });
              }}
              className="forgot_password"
              variant="caption"
            >
              Forgot Password?
            </Link>
            <Button
              disabled={handleIsDisabled()}
              color="primary"
              variant="contained"
              onClick={handleLogin}
              sx={{ color: "#fff", padding: "0.5rem 2rem" }}
            >
              LOGIN
            </Button>
          </Box>
        </Grid>
      </Box>
      <Box
        className="details"
        sx={{ backgroundColor: "#78e6dd", width: "100%", height: "100%" }}
      >
        <SideFirstScreen />
      </Box>
      {loginIsLoading && <ViraladLoader isloading={loginIsLoading} />}
      {errorMsg !== "" || !loginIsLoading ? (
        <Snackbar
          open={errorMsg !== ""}
          autoHideDuration={6000}
          onClose={handleCloseSnackBar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnackBar}
            severity="error"
            sx={{ width: "100%" }}
          >
            {errorMsg}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  );
};

export default Signup;
