import React from 'react'

const Iscreen = (props) => {
  const { frameDetails } = props;
  return (
    <div className="mobile-frame-container">
      <iframe
        src={frameDetails.iframe_url}
        style={{
          width: frameDetails.width * 1.5,
          height: "100%",
        }}
        title="iframe"
      ></iframe>
    </div>
  );
};

export default Iscreen