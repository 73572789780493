import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ParallaxView = ({ iframeUrl }) => {
  const [object, setObject] = useState();
  let location = useLocation();
  let query = useQuery();

  useEffect(() => {
    setObject(location.pathname);
  }, [location]);

  return object ? (
    <div style={{ height: "100vh", position: "relative" }}>
      <iframe
        allow="camera;microphone"
        width={"100%"}
        height="100%"
        src={`https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/parallax_files/preview.html?adloc=${query.get("adloc")}&width=${query.get("width")}&height=${query.get("height")}`}
        title="creative"
      ></iframe>
    </div>
  ) : (
    <div>...Loading</div>
  );
};

export default ParallaxView;
