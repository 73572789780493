import React, { useEffect, useState } from "react";
import "./index.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Snackbar, Container } from "@mui/material";

// --------------- Import Components ---------------------------//
import UserDetails from "./UserDetails";
import ErrorScreen from "../../../components/common/ErrorScreen";
import ViralAdLoader from "../../../components/common/ViraladLoader";

// --------------- Import APICall functions ------------------//
import useGetAllUsers from "../data/useGetAllUsers";
import useGetUserRoles from "../data/useGetUserRoles";
import useGetUserStatus from "./../data/useGetUserStatus";
import useUpdateUserDetails from "../data/useUpdateUserStatus";

const UserAttributes = [
  "id",
  "email",
  "First Name",
  "Last Name",
  "Organisation",
  "Designation",
  "Contact No.",
  "Status",
  "Role",
  "Action",
];

const ManageUsers = () => {
  const [allUsersData, allUsersError, allUsersIsLoading, getAllUsers] =
    useGetAllUsers();
  const [rolesData, rolesError, rolesIsLoading, getUserRoles] =
    useGetUserRoles();
  const [statusData, , statusIsLoading, getUserStatus] = useGetUserStatus();
  const [updatedData, , updatedIsLoading, setUpdatedStatus] =
    useUpdateUserDetails();
  const [usersData, setUsersData] = useState([]);
  const [snackbarStatus, setSnackbarStatus] = useState({
    status: false,
    message: "",
    severity: "error",
    color: "error",
  });
  const UserData = allUsersData ? allUsersData : [];
  const [status, setStatus] = useState();

  useEffect(() => {
    getAllUsers();
    getUserRoles();
    getUserStatus();
  }, []);

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarStatus({
      status: false,
      message: "",
      severity: "error",
      color: "error",
    });
  };

  useEffect(() => {
    if (allUsersData) {
      let currentUser = JSON.parse(sessionStorage.getItem("userDetails")).email;
      let users = allUsersData.filter((user) => user.email !== currentUser);
      setUsersData(users);
    }
  }, [allUsersData, allUsersError]);

  useEffect(() => {
    if (rolesError) {
      setSnackbarStatus({
        status: false,
        message: "Something went wrong fetching the Roles",
        severity: "error",
        color: "error",
      });
    }
  }, [rolesError]);

  useEffect(() => {
    if (updatedData) {
      setSnackbarStatus({
        status: true,
        message: "User details updated successfully",
        severity: "success",
        color: "success",
      });
    }
  }, [updatedData]);

  function handleRole(thisRole) {
    if (rolesData) {
      let userRole = rolesData.filter((role) => role.value === thisRole);
      return userRole[0].key;
    } else return null;
  }

  return (
    <React.Fragment>
      <Container className="container">
        {allUsersError ? (
          <ErrorScreen />
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ backgroundColor: "#616161" }}>
                <TableRow>
                  {UserAttributes.map((attr, i) => (
                    <TableCell key={i} sx={{ color: "#ffffff" }}>
                      {attr}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {allUsersIsLoading && rolesIsLoading && statusIsLoading
                  ? "Loading..."
                  : UserData.length === 0
                  ? "Zero user Available"
                  : usersData.map((user) => (
                      <UserDetails
                        user={user}
                        status={status}
                        statusData={statusData}
                        handleRole={handleRole}
                        setStatus={setStatus}
                        setUpdatedStatus={setUpdatedStatus}
                      />
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Container>
      {snackbarStatus.status && (
        <Snackbar
          severity={snackbarStatus.severity}
          open={snackbarStatus.status}
          autoHideDuration={6000}
          onClose={handleSnackBarClose}
          message={snackbarStatus.message}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        />
      )}
      {updatedIsLoading ? <ViralAdLoader isloading={updatedIsLoading} /> : null}
    </React.Fragment>
  );
};

export default ManageUsers;
