import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import useGetUserTemplates from "../data/getUserTemplates";
import { StyledDrawer } from "../../../muiStyled/drawers";
import ViewTemplate from "../../../modules/viewTemplates";
import UserCreatives from "../../../modules/userCreatives";

const MyProjects = () => {
  const [templatesData, errorTemplateData, isLoading, getTemplatesData] =
    useGetUserTemplates();
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [creativeDetails, setCreativeDetails] = useState();

  useEffect(() => {
    getTemplatesData();
  }, []);

  const handleViewCreative = (frameDetails) => {
    setCreativeDetails(frameDetails);
    setDrawerIsOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerIsOpen(false);
  };

  return (
    <React.Fragment>
      <UserCreatives
        isLoading={isLoading}
        templatesData={templatesData}
        getTemplatesData={getTemplatesData}
        errorTemplateData={errorTemplateData}
        handleViewCreative={handleViewCreative}
      />
      <StyledDrawer
        anchor="right"
        open={drawerIsOpen}
        onClose={handleDrawerClose}
      >
        <ViewTemplate frameDetails={creativeDetails} />
        <CloseIcon
          sx={{ cursor: "pointer", position: "absolute", right: 10, top: 10 }}
          color={"#000"}
          className="imcross"
          onClick={handleDrawerClose}
        />
      </StyledDrawer>
    </React.Fragment>
  );
};

export default MyProjects;
