import { Drawer } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledDrawer = styled(Drawer)(() => ({
  ".MuiDrawer-paper": {
    width: "70%",
    padding: "0",
    display: "flex",
    background: "#f5f5f5",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const UploadCreativeDrawer = styled(Drawer)(() => ({
  ".MuiDrawer-paper": {
    backgroundColor: "#e2e2e2",
    padding: "8px 8px 24px 8px",
    width: "35%",
  },
}));
