import React, { useState, useEffect } from "react";
import "./viewtemplate.scss";
import { useLocation, Link, useNavigate } from "react-router-dom";
import {
  TextField,
  Typography,
  InputAdornment,
  Grid,
  Button,
  Snackbar,
  Alert,
  IconButton,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Download } from "@mui/icons-material";
import useDownloadCreative from "./useDownloadCreative";
import usePostCreateNewTemplate from "./usePostCreateNewTemplate";
import ViraladLoader from "../../components/common/ViraladLoader";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Iscreen from "../../components/common/IFrameScreen";
import CloseIcon from "@mui/icons-material/Close";

const ViewTemplate = (props) => {
  const { frameDetails } = props;
  const [pathLocation, setpathLocation] = useState(false);
  const Location = useLocation();
  const navigate = useNavigate();
  const [sharableLink, setSharableLink] = useState("");
  const [dnResponse, dnError, dnIsLoading, downloadCreative] =
    useDownloadCreative();
  const [templateData, templateErr, templateIsLoading, createNewTemplate] =
    usePostCreateNewTemplate();
  const [snackbarDetails, setSnackbarDetails] = useState({
    message: "",
    status: false,
    severity: "",
  });

  useEffect(() => {
    setpathLocation(Location.pathname.split("/")[1]);
    if (frameDetails) {
      var url = frameDetails.iframe_url;
      var sparams = url.split("?")[1];
      const params = sparams.split("&");
      const values = params.map((param) => {
        return param.split("=")[1];
      });
      let link = `/viralad/${frameDetails.name}?adloc=${values[0]}&width=${values[1]}&height=${values[2]}`;

      setSharableLink(link);
    }
  }, [frameDetails, Location]);

  const handleDownload = () => {
    downloadCreative(frameDetails.id);
  };

  useEffect(() => {
    if (dnResponse) {
      window.open(dnResponse.data, "_blank");
    } else if (dnError) {
      setSnackbarDetails({
        message: dnError,
        status: false,
        severity: "error",
      });
    }
  }, [dnResponse, dnIsLoading]);

  // ============== THIS HAS TO BE CORRECTED ========== //

  useEffect(() => {
    // depending upon user navigate accordingly.
    if (templateData) {
      navigate(`/edit/${templateData.name}/${templateData.id}/`, {
        state: templateData.id,
        replace: true,
      });
    } else if (templateErr) {
      setSnackbarDetails({
        message: templateErr,
        status: false,
        severity: "error",
      });
    }
  }, [templateData, templateIsLoading]);

  const handleUserTemplate = () => {
    // calling createNewProject API
    createNewTemplate(frameDetails.id);
  };
  // ============== THIS HAS TO BE CORRECTED ========== //

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarDetails({ message: "", status: false, severity: "" });
  };

  return (
    <div className="view_temp">
      <Iscreen frameDetails={props.frameDetails} />
      <React.Fragment>
        <Grid
          spacing={2}
          container
          direction={"column"}
          className="viraldetails"
        >
          <Grid item>
            <Typography
              color="secondary"
              sx={{
                fontFamily: "Montserrat-Bold",
                color: "#333",
                fontSize: 30,
              }}
            >
              {frameDetails.brand}{" "}
            </Typography>
          </Grid>
          <Grid item>
            {sharableLink ? (
              <TextField
                fullWidth
                type="text"
                label="Sharable Link"
                readOnly={true}
                variant="outlined"
                aria-readonly="true"
                inputProps={{
                  style: {
                    backgroundColor: "#f5f5f5",
                    borderRadius: "4px 0 0 4px",
                    borderRight: "1px solid grey",
                  },
                }}
                value={`${process.env.REACT_APP_DOMAIN}${sharableLink}`}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Link
                        style={{ height: "24px" }}
                        to={sharableLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <OpenInNewIcon
                          color="primary"
                          style={{ cursor: "pointer" }}
                        />
                      </Link>
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            ) : null}
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              color="primary"
              variant="contained"
              startIcon={<Download />}
              onClick={handleDownload}
              sx={{
                fontWeight: "bold",
                fontSize: "1rem",
                marginRight: "0.5rem",
              }}
            >
              Download
            </Button>
            <Button
              color="secondary"
              variant="contained"
              startIcon={<ContentCopyIcon />}
              onClick={handleUserTemplate}
              sx={{ fontWeight: "bold", fontSize: "1rem" }}
            >
              Create Copy
            </Button>
          </Grid>
        </Grid>
        {(dnIsLoading || templateIsLoading) && (
          <ViraladLoader isloading={dnIsLoading || templateIsLoading} />
        )}
        <Snackbar
          onClose={handleClose}
          autoHideDuration={6000}
          open={snackbarDetails.status}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <div>
            <Alert
              variant="filled"
              onClose={handleClose}
              sx={{ width: "100%" }}
              severity={snackbarDetails.severity ?? "success"}
              action={
                <IconButton size="small" color="inherit" onClick={handleClose}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              }
            >
              {snackbarDetails.message}
            </Alert>
          </div>
        </Snackbar>
      </React.Fragment>
    </div>
  );
};

export default ViewTemplate;
