//------------- Import Packages ------------------//
import Select from "react-select";
import UploadIcon from "@mui/icons-material/Upload";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import React, { useState, useRef, useEffect } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Box, Button, Tooltip, IconButton, Typography } from "@mui/material";

// ------------ Import Components ----------------//
import MultiSelect from "../MultiSelect";
import useGetBCC from "./data/useGetBCC";
import { UploadCreativeDrawer } from "../../../muiStyled/drawers";

// -------------- Import Styles ------------------//
import "./styles.scss";

const AdUploadDrawer = (props) => {
  const brandNameRef = useRef();
  const uploadThumbRef = useRef();
  const uploadFileRef = useRef();
  const { setuploadmodal, postDataTemplatefunction } = props;
  const [brandName, setBrandName] = useState("");
  const [apiBrands, setAllApiBrands] = useState([]);
  const [invalidFormFields, setInvalidFormFields] = useState(false);
  const [activeAddNewBrand, setActiveAddNewBrand] = useState(false);
  const [categoryList, setCategoryList] = useState("");
  const [thumbnail, setThumbnail] = useState([]);
  const [uploadFile, setuploadFile] = useState([]);

  const [bccData, , , getBDD] = useGetBCC();
  const [categoriesData, , , getCategories] = useGetBCC();

  useEffect(() => {
    if (brandName === "" || thumbnail.length === 0 || uploadFile.length === 0) {
      setInvalidFormFields(true);
    } else {
      setInvalidFormFields(false);
    }
  }, [brandName, thumbnail, uploadFile]);

  useEffect(() => {
    getBDD("brands");
  }, []);

  useEffect(() => {
    let tempBrandInputData = [];

    if (bccData && apiBrands.length === 0) {
      tempBrandInputData = bccData;
      tempBrandInputData.push({ label: "Add New Brand", value: "add" });
      setAllApiBrands(tempBrandInputData);
    }
  }, [bccData]);

  const handleDrawerClose = () => {
    setuploadmodal(false);
  };

  const handleUploadingCreative = (event) => {
    let fileDetails = [];
    fileDetails.push(event.target.files[0]);
    fileDetails.push(event.target.value.split("\\").at(-1));
    setuploadFile(fileDetails);
  };

  function handleUploadThumbnail(event) {
    let fileDetails = [];
    fileDetails.push(event.target.files[0]);
    fileDetails.push(event.target.value.split("\\").at(-1));
    setThumbnail(fileDetails);
  }

  function handleBrandChange(e) {
    if (e.value === "add") {
      setActiveAddNewBrand(true);
      getCategories("categories");
      setBrandName("");
    } else {
      setBrandName(e.label);
    }
  }

  function handleNewBrandChange(e) {
    setBrandName(e.target.value);
  }

  function handleSubmit() {
    postDataTemplatefunction(
      uploadFile[0],
      thumbnail[0],
      brandName,
      categoryList
    );
    setuploadmodal(false);
  }

  const handleRemoveFile = (setter) => {
    setter([]);
  };

  function handleCategories(values) {
    let ids = values.map((value) => value.value);
    let allIds = ids.join(",");
    setCategoryList(allIds);
  }

  return (
    <UploadCreativeDrawer
      anchor={"right"}
      open={true}
      onClose={handleDrawerClose}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          marginBottom: 1,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Montserrat-Bold",
          }}
          variant="body1"
        >
          Upload ViralAd
        </Typography>
        <IconButton onClick={handleDrawerClose}>
          <CloseRoundedIcon />
        </IconButton>
      </Box>

      <div className="updateModalContent">
        <Box className="uploadWrapper" sx={{ width: "100%" }}>
          <div className="input-choice">
            <label className="input-lable" htmlFor="brand_name">
              Brand Name
            </label>
            {!activeAddNewBrand ? (
              <Box className="input_item">
                <Select
                  ref={brandNameRef}
                  id="brand_name"
                  name="brand_name"
                  options={apiBrands}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  noOptionsMessage={() =>
                    "Select 'Add New' option from the list"
                  }
                  onChange={handleBrandChange}
                />
              </Box>
            ) : (
              <React.Fragment>
                <Box className="input_item">
                  <Box sx={{ display: "flex" }}>
                    <input
                      type="text"
                      className="brand_name"
                      id="brand_name"
                      name="brand_name"
                      value={brandName}
                      placeholder="Enter Brand Name"
                      onChange={handleNewBrandChange}
                    />
                    <Tooltip title="Select from existing brands">
                      <IconButton onClick={() => setActiveAddNewBrand(false)}>
                        <ReplyAllIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
                {categoriesData ? (
                  <Box className="input_item">
                    <MultiSelect
                      label={"Categories"}
                      data={categoriesData}
                      handleCategories={handleCategories}
                    />
                  </Box>
                ) : null}
              </React.Fragment>
            )}
          </div>
          <div className="input-uploads">
            <Box className="upload-box">
              {uploadFile.length === 0 ? (
                <div className="button-choice">
                  <input
                    ref={uploadFileRef}
                    type="file"
                    accept=".zip"
                    multiple={false}
                    id="upfile"
                    onChange={handleUploadingCreative}
                  />

                  <Button
                    onClick={() => {
                      uploadFileRef.current.click();
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Upload File
                  </Button>
                </div>
              ) : (
                <Button
                  onClick={() => handleRemoveFile(setuploadFile)}
                  variant="contained"
                  color="error"
                  sx={{ margin: '0.5rem' }}
                >
                  REMOVE
                </Button>
              )}
              {uploadFile.length !== 0 && (
                <Typography variant="body1" className="uploadedFilename">{uploadFile[1]}</Typography>
              )}
            </Box>

            <Box className="upload-box">
              {thumbnail.length === 0 ? (
                <div className="button-choice">
                  <input
                    ref={uploadThumbRef}
                    type="file"
                    id="upfile2"
                    multiple={false}
                    onChange={handleUploadThumbnail}
                    accept="image/png, image/jpeg, image/jpg"
                  />
                  <Button
                    onClick={() => {
                      uploadThumbRef.current.click();
                    }}
                    variant="contained"
                    color="primary"
                    
                  >
                    Upload Thumbnail
                  </Button>
                </div>
              ) : (
                <Button
                  onClick={() => handleRemoveFile(setThumbnail)}
                  variant="contained"
                  color="error"
                  sx={{ margin: '0.5rem' }}
                >
                  REMOVE
                </Button>
              )}
              {thumbnail.length !== 0 && (
                <Typography variant="body1" className="uploadedFilename">{thumbnail[1]}</Typography>
              )}
            </Box>
          </div>
          {invalidFormFields && (
            <Typography variant="caption" className="error">
              *All input fields are required
            </Typography>
          )}
          <div className="submit-admin">
            <Button
              color="success"
              disabled={false}
              variant="contained"
              onClick={handleSubmit}
              endIcon={<UploadIcon />}
              sx={{ fontFamily: "Montserrat-Bold", color: "#fff" }}
            >
              Submit
            </Button>
          </div>
        </Box>
      </div>
    </UploadCreativeDrawer>
  );
};

export default AdUploadDrawer;
