import React, { useState } from "react";
import ImageField from "../../../components/common/Edit/Elements/ImageField";
import TextField from "../../../components/common/Edit/Elements/TextField";
import CarouselField from "../../../components/common/Edit/Elements/CarouselField";
import Urlfield from "../../../components/common/Edit/Elements/UrlField";
import CtaUrl from "../../../components/common/Edit/Elements/CtaUrl";
import { Alert, IconButton } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";

const Fieldwrap = (props) => {
  const { activeFrame, setReloadingState } = props;
  const [loaderShow, setLoader] = useState(false);
  const [postResponseMessage, setPostResponseMessage] = useState({
    message: "",
    status: false,
    resStatus: 0,
  });

  function onDismiss(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    setPostResponseMessage({ message: "", status: false, resStatus: 0 });
    setReloadingState({});
  }

  return (
    <>
      <div className="field-wrapper">
        <div className="left-field">
          {activeFrame &&
            activeFrame.medias.map((media, i) => {
              let format = media.media_src.split(".").at(-1);
              return (
                <ImageField
                  key={i}
                  media={media}
                  setLoader={setLoader}
                  format={`.${format}`}
                  setPostResponseMessage={setPostResponseMessage}
                />
              );
            })}

          {activeFrame &&
            activeFrame.carousels.map((carouselData, i) => {
              return (
                <CarouselField
                  key={i}
                  carouselData={carouselData}
                  setLoader={setLoader}
                  setPostResponseMessage={setPostResponseMessage}
                />
              );
            })}
        </div>
        <div className="right-field">
          {activeFrame &&
            activeFrame.texts.map((text, i) => {
              return (
                <TextField
                  key={i}
                  index={i}
                  text={text}
                  setLoader={setLoader}
                  activeFrame={activeFrame}
                  setPostResponseMessage={setPostResponseMessage}
                />
              );
            })}
          {activeFrame &&
            activeFrame.youtube_videos.map((youtubevideo, i) => {
              return (
                <Urlfield
                  key={i}
                  index={i}
                  setLoader={setLoader}
                  youtubevideo={youtubevideo}
                  setPostResponseMessage={setPostResponseMessage}
                />
              );
            })}

          {activeFrame &&
            activeFrame.cta_urls.map((cta_urls, i) => {
              return (
                <CtaUrl
                  key={i}
                  index={i}
                  setLoader={setLoader}
                  cta_urls={cta_urls}
                  setPostResponseMessage={setPostResponseMessage}
                />
              );
            })}
        </div>
      </div>
      {postResponseMessage.status && (
        <Alert
          variant="filled"
          onClose={onDismiss}
          severity={
            postResponseMessage.resStatus === true ? "success" : "error"
          }
          action={
            <IconButton size="small" color="inherit" onClick={onDismiss}>
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          {postResponseMessage.message}
        </Alert>
      )}
      {loaderShow && (
        <div className="TailSpin">
          <CircularProgress color="inherit" />
        </div>
      )}
    </>
  );
};

export default Fieldwrap;
