import React from "react";
import { Box, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

const ProfileContainerBox = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  padding: "16px",
  display: "flex",
  paddingTop: "2rem",
  flexDirection: "column",
  justifyContent: "flex-start",
}));

const UserProfile = () => {
  return (
    <ProfileContainerBox>
      <Box sx={{ marginBottom: "10px", display: "flex" }}>
        <TextField
          label="First name"
          type="text"
          sx={{ marginRight: 1 }}
          variant="standard"
          fullWidth
          value={""}
          disabled
          name="firstname"
        />
        <TextField
          label="Last name"
          type="text"
          variant="standard"
          fullWidth
          value={""}
          disabled
          name="lastname"
        />
      </Box>
      <Box
        sx={{
          backgroundColor: "#eeeeee",
          borderRadius: "4px",
          height: "200px",
          width: "100%",
        }}
      ></Box>
      <Box sx={{ marginTop: "16px" }}>
        <Box sx={{ marginBottom: "10px" }}>
          <TextField
            label="Organisation"
            type="text"
            variant="standard"
            fullWidth
            value={""}
            readOnly
            name="organisation"
          />
        </Box>
        <Box sx={{ marginBottom: "10px" }}>
          <TextField
            label="Designation"
            type="text"
            variant="standard"
            fullWidth
            value={""}
            disabled
            name="designation"
          />
        </Box>
        <Box sx={{ marginBottom: "10px" }}>
          <TextField
            label="Contact Number"
            type="text"
            variant="standard"
            fullWidth
            value={""}
            disabled
            name="contact"
          />
        </Box>
      </Box>
    </ProfileContainerBox>
  );
};

export default UserProfile;
