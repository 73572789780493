import React, { useState, useEffect, useRef } from "react";
import "./styles.scss";
import { useLocation } from "react-router-dom";
import { Box, Skeleton, Snackbar, Alert, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Pagination from "@mui/material/Pagination";

// ------------- Import Components ------------------//
import useDeleteViralad from "./data/useDeleteViralad";
import ZeroData from "../../components/common/ZeroData";
import useGetFilteredData from "./data/useGetFilteredData";
import DeleteValidation from "../../components/common/DeleteValidation";
import CreativeCard from "./../../components/common/CreativeCard/index";
import PinterestStack from "./PinterestStack";
import ErrorScreen from "../../components/common/ErrorScreen";
import SidePanel from "../../components/common/SidePanel";

export const LIMIT = 50;
const Creatives = (props) => {
  const { handleViewCreative } = props;
  const location = useLocation();

  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [creativeId, setCreativeId] = useState();
  const [currentPath, setCurrentPath] = useState();
  const [deleteResponse, deleteError, deleteIsLoading, deleteViralad] =
    useDeleteViralad();
  const [
    filteredCreatives,
    filteredError,
    filteredIsLoading,
    getFilteredCreatives,
  ] = useGetFilteredData();

  const [completeData, setCompleteData] = useState([]);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [deleteModalState, setDeleteModalState] = useState(false);
  const [filterInputData, setFilterInputData] = useState({
    categories: null,
    brands: null,
    components: null,
  });
  const [snackbarDetails, setSnackbarDetails] = useState({
    message: "",
    status: false,
    severity: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const filterLoadingRef = useRef(false);

  filterLoadingRef.current = filteredIsLoading;

  const handleGetFilteredData = (currentOffset, lastPage, currentPage) => {
    let lPage = lastPage;
    if (currentPage - lastPage > 1) {
      setIsLoading(true);
      const interval = setInterval(() => {
        if (!filterLoadingRef.current) {
          if (lPage < currentPage) {
            getFilteredCreatives({
              ...filterInputData,
              limit: LIMIT,
              offset: lPage * LIMIT,
            });
            lPage++;
          } else {
            setIsLoading(false);
            clearInterval(interval);
          }
        }
      }, 500);
    } else {
      getFilteredCreatives({
        ...filterInputData,
        limit: LIMIT,
        offset: currentOffset,
      });
    }
  };

  useEffect(() => {
    if (completeData.length >= 0) {
      const startIndex = (currentPage - 1) * LIMIT;
      const endIndex = startIndex + LIMIT;
      setCurrentPageData(completeData.slice(startIndex, endIndex));
    }
  }, [currentPage, completeData]);

  useEffect(() => {
    if (
      filteredCreatives?.data &&
      typeof filteredCreatives?.data === "string" &&
      filteredCreatives?.data === "Error! data not found"
    ) {
      setCompleteData((prev) => prev);
    } else if (
      filteredCreatives &&
      typeof filteredCreatives === "object" &&
      filteredCreatives?.results.length >= 0
    ) {
      let tempData = [];
      if (offset > 0) {
        tempData = [...completeData];
      }
      if (completeData.length <= offset * LIMIT) {
        setTotalPages(Math.ceil(filteredCreatives?.count / LIMIT));
        tempData = tempData.concat(filteredCreatives?.results);
        setCompleteData(tempData);
      }
    }
  }, [filteredCreatives]);

  useEffect(() => {
    if (offset === 0) {
      setCompleteData([]);
      handleGetFilteredData(offset, currentPage, currentPage);
    }
    setCurrentPath(location?.pathname);
  }, [filterInputData, deleteIsLoading, deleteError]);

  useEffect(() => {
    if (deleteResponse) {
      setSnackbarDetails({
        message: `Creative Deleted Successfully`,
        status: true,
        severity: "success",
      });
    }
  }, [deleteResponse]);

  const handleModalClose = () => {
    setDeleteModalState(false);
  };

  const deleteTemplate = () => {
    setDeleteModalState(false);
    let dataArray = ["template", creativeId];
    deleteViralad(dataArray);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarDetails({ message: "", status: false, severity: "" });
  };

  return (
    <section className="adminBody">
      <Box className="adminBodyWrapper">
        <SidePanel
          setOffset={setOffset}
          setCurrentPage={setCurrentPage}
          currentPath={currentPath}
          filterInputData={filterInputData}
          setFilterInputData={setFilterInputData}
          getFilteredCreatives={getFilteredCreatives}
        />
        {filteredError ? (
          <ErrorScreen />
        ) : filteredIsLoading || isLoading || filterLoadingRef.current ? (
          <Box sx={{ height: "100%", width: "100%", paddingLeft: "1rem" }}>
            <PinterestStack className="right_content">
              {Array(12)
                .fill()
                .map((_, index) => (
                  <Skeleton
                    key={index}
                    width={"80%"}
                    height={"95%"}
                    variant="rectangular"
                    className="pinterest_card small"
                    style={{
                      margin: "10px",
                      border: "none!important",
                      backgroundColor: "rgba(0,0,0,0.3)",
                    }}
                    sx={{ borderRadius: "8px", margin: "0 0.25rem" }}
                  />
                ))}
            </PinterestStack>
          </Box>
        ) : currentPageData && currentPageData.length !== 0 ? (
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexFlow: "column",
            }}
          >
            <PinterestStack className="right_content">
              {currentPageData.map((template, i) => {
                return (
                  <CreativeCard
                    key={i}
                    template={template}
                    currentPath={currentPath}
                    setCreativeId={setCreativeId}
                    handleViewCreative={handleViewCreative}
                    setDeleteModalState={setDeleteModalState}
                  />
                );
              })}
            </PinterestStack>
            <Pagination
              sx={{
                width: "100%",
                flex: "0 1 auto",
                padding: "10px",
                backgroundColor: "#f2f2f2",
              }}
              count={totalPages}
              page={currentPage}
              onChange={(event, page) => {
                setCurrentPage(page);
                if (page > offset / LIMIT + 1) {
                  handleGetFilteredData((page - 1) * LIMIT, currentPage, page);
                  setOffset((page - 1) * LIMIT);
                }
              }}
            />
          </Box>
        ) : (
          <ZeroData />
        )}
        {deleteModalState && (
          <DeleteValidation
            modalState={deleteModalState}
            handleModalClose={handleModalClose}
            deleteTemplate={deleteTemplate}
          />
        )}
        <Snackbar
          onClose={handleClose}
          autoHideDuration={6000}
          open={snackbarDetails?.status}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <div>
            <Alert
              variant="filled"
              onClose={handleClose}
              sx={{ width: "100%" }}
              severity={snackbarDetails?.severity ?? "success"}
              action={
                <IconButton size="small" color="inherit" onClick={handleClose}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              }
            >
              {snackbarDetails?.message}
            </Alert>
          </div>
        </Snackbar>
      </Box>
    </section>
  );
};

export default Creatives;
