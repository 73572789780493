import axios from "axios";

export const validator = (value = "", name, length = 0) => {
  let regText = "";
  let exp = "";
  if (name && value) {
    if (
      name === "firstName" ||
      name === "lastName" ||
      name === "designation" ||
      name === "organisation"
    ) {
      exp = `^[a-zA-Z0-9 ]{1,${length}}$`;
    } else if (name === "email") {
      exp = `^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$`;
    } else if (name === "contact") {
      exp = `^[0-9]{1,${length}}$`;
    }
  }
  regText = new RegExp(exp, "g");
  return regText.test(value);
};

export const logout = (setIsAuthenticated) => {
  sessionStorage.removeItem("authToken");
  sessionStorage.removeItem("userDetails");
  delete axios.defaults.headers.common["Authorization"];
  setIsAuthenticated(false);
};

export const checkAuthenticated = (setIsAuthenticated) => {
  if (sessionStorage.getItem("authToken")) {
    setIsAuthenticated(true);
  } else {
    setIsAuthenticated(false);
  }
};
