// ------------- Import Packages ------------------ //
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { StyledDrawer } from "../../muiStyled/drawers";

// -------------- Import Modules -------------------//
import Creatives from "../../modules/creatives";
import ViewTemplate from "../../modules/viewTemplates";

const AdminHome = () => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [creativeDetails, setCreativeDetails] = useState();
  const handleViewCreative = (frameDetails) => {
    setCreativeDetails(frameDetails);
    setDrawerIsOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerIsOpen(false);
  };

  return (
    <React.Fragment>
      <Creatives handleViewCreative={handleViewCreative} />
      <StyledDrawer
        anchor="right"
        open={drawerIsOpen}
        onClose={handleDrawerClose}
      >
        <ViewTemplate frameDetails={creativeDetails} />
        <CloseIcon
          sx={{ cursor: "pointer", position: "absolute", right: 10, top: 10 }}
          color={"#000"}
          className="imcross"
          onClick={handleDrawerClose}
        />
      </StyledDrawer>
    </React.Fragment>
  );
};

export default AdminHome;
