import en from "../../../Lang/en.json";
import useAPICall from "../../../hooks/useAPICall";
import { UPDATE_USER_STATUS } from "../../../utilities/apis";

const useUpdateUserStatus = () => {
  const [data, error, isLoading, callGetData, setSuccessData, setError] =
    useAPICall(undefined, undefined);

  const defaultFallback = () => {
    setError(en.something_went_wrong);
    setSuccessData(undefined);
  };

  const statusObj = [
    {
      status_code: "200",
      status_txt: "OK",
      callBack: (res) => {
        const data = res.data;
        if (data && typeof data === "object") {
          setSuccessData(res.data);
        } else {
          defaultFallback();
        }
      },
    },
    {
      status_txt: "Bad Request",
      status_code: "400",
      callBack: defaultFallback,
    },
    {
      status_txt: "Internal Server Error",
      status_code: 500,
      callBack: defaultFallback,
    },
  ];

  const updateUserStatus = (userId, body) => {
    const url = `${process.env.REACT_APP_API_URL}${UPDATE_USER_STATUS}${userId}/`;
    callGetData({
      url,
      statusObj,
      defaultFallback,
      method: "PATCH",
      body,
    });
  };
  return [data, error, isLoading, updateUserStatus, setSuccessData, setError];
};

export default useUpdateUserStatus;
