// ---------------- Imports Packages ---------------------//
import React, { createContext, useEffect, useState } from "react";
import { useRoutes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

// ---------------- Imports Components ---------------------//
import theme from "./theme";
import routes from "./routes";
import en from "./Lang/en.json";
import { checkAuthenticated } from "./utilities/functions";
import ViraladLoader from './components/common/ViraladLoader';
import useGetUserDetails from "./data/useGetUserDetails";

// ---------------- Imports Contexts ---------------------//
export const AuthStateContext = createContext();
export const UserDetailsContext = createContext();

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userDetails, setUserDetails] = useState(undefined);
  const [userData, errorData, isLoading, callGetData] = useGetUserDetails();

  useEffect(() => {
    checkAuthenticated(setIsAuthenticated);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      callGetData();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (userData) {
      setUserDetails(userData);
    }
  }, [isLoading, userData]);

  const routing = useRoutes(
    routes(isAuthenticated, JSON.parse(sessionStorage.getItem("userDetails")))
  );

  if (isLoading) {
    return (
      <ViraladLoader isloading={isLoading} />
    );
  } else if (errorData === en.something_went_wrong) {
    return (
      <div className="errorScreen">
        <ErrorIcon sx={{ marginRight: 1 }} />
        <Typography variant="body1">{errorData}</Typography>
      </div>
    );
  } else {
    return (
      <ThemeProvider theme={theme}>
        <AuthStateContext.Provider
          value={{ isAuthenticated, setIsAuthenticated }}
        >
          <UserDetailsContext.Provider value={{ userDetails, setUserDetails }}>
            {routing}
          </UserDetailsContext.Provider>
        </AuthStateContext.Provider>
      </ThemeProvider>
    );
  }
};

export default App;
